import React, { useEffect, useState } from 'react';
import './App.css';
import {  Typography, Box, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import TiserSlider from './TiserSlider'; // Assurez-vous que ce chemin est correct
import { Button } from '@douyinfe/semi-ui';
import ModalComponent from './ModalComponent';

const useStyles = makeStyles((theme) => ({
  ribbon: {
    position: 'absolute',
    top: '4px',
    left: '-54.78%',
    padding: '2px 8px',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '0 0 3px 0',
    zIndex: 1,
    fontSize: '12px',
  },
  detailContainer: {
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  linkStyle: {
    color: '#0000FF',
    cursor: 'pointer',
    textDecoration: 'none', // Ensure links don't have underline by default
  },
}));

const CustomTimeline = () => {
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const navigate  = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    handleToggle();
    // Autres actions à effectuer à la fermeture du modal
  };
  useEffect(() => {
    const handleScroll = () => {
      const timeline = document.querySelector('.ct-timeline-wrapper');
      const car = document.querySelector('.car-icon');
      if (timeline && car) {
        const rect = timeline.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          car.classList.add('animate');
        } else {
          car.classList.remove('animate');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClickTheory = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){
      navigate(`/${i18n.language}/Theory/17`);

    }else{
      navigate(`/${i18n.language}/Theory/69`);

      
    }
  };
  const handleClickSensi = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){

    navigate(`/${i18n.language}/Sensibilisation/16`);
    }else{
      navigate(`/${i18n.language}/Sensibilisation/67`);

    }
    
  };
  const handleClickFirstAid = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){

      navigate(`/${i18n.language}/FirstAid/15`);
    }else{
      navigate(`/${i18n.language}/FirstAid/68`);
  
      }
    
  };
  return (
    <div>
      <Typography variant="h2" className="title price__title" style={{ fontFamily: 'Montserrat', textAlign: 'center', paddingTop: '80px', color: '#444444' }}>
        <span className="title__mark" style={{ fontSize: '30px' }}>{t('timeline1.stepsTitle')}</span>
      </Typography>
      <Typography style={{ textAlign: 'center', fontSize: '18px', color: '#000', paddingLeft: '240px', paddingRight: '240px', fontFamily: 'inherit', lineHeight: '1.6', fontWeight: '400' }}>
        {t('timeline1.courseDetails')}
      </Typography>

      <Box component="section" className="ct-timeline-wrapper" style={{ marginBottom: '6%', marginTop: '4%' }}>
        <div className="ct-middle-line"></div>
        <div className="ct-middle-line1"></div>
        <FontAwesomeIcon icon={faCarSide} className="car-icon" />

        <Box className="ct-box ct-box-top">
          <Box className="ct-date">
            <Typography variant="h2" style={{ fontSize: '40px', height: '115px', marginLeft: '20px',marginBottom:"10px" }}>🏁</Typography>
          </Box>
          <Box className="ct-box-content">
            <Typography style={{ marginBottom: '6%' ,fontSize:"13px",fontWeight:"bold"}}>{t('timeline1.practicalExam')}</Typography>
            <Typography style={{ fontSize: '14px', color: '#7898b8' }}>
              <a href="https://ge.ch/cari-online/examensPublic" className={classes.linkStyle}>{t('timeline1.registerForPracticalExam')}</a>
            </Typography>
          </Box>
        </Box>

        <Box className="ct-box ct-box-bottom">
          <Box className="ct-date">
            <br/>
            <Typography style={{ fontSize: '30px', height: '5px' }}>📍</Typography>
          </Box>
          <Box className="ct-box-content">
          <Box  style={{ marginLeft: '1%', backgroundColor: '#B0E0E6', color: 'red' ,fontWeight:"bold",fontSize:"12px"}}>{t('timeline.priceFrom')} 75 CHF</Box>

            <Typography style={{ marginBottom: '6%', marginTop: '6%',fontSize:"13px",fontWeight:"bold" }}>{t('timeline1.drivingLessons')}</Typography>
            <button className="btn popup-protect-btn"  
            onClick={handleToggle}                                  
                                >
                               {t('timeline1.book')} 
                                </button>                </Box>
        </Box>

        <Box className="ct-box ct-box-top">
          <Box className="ct-date">
            <Typography style={{ fontSize: '30px', height: '95px',marginTop:"10px"  }}>📍</Typography>
          </Box>
          <Box className="ct-box-content">
          <Box  style={{ marginLeft: '1%', backgroundColor: '#B0E0E6', color: 'red' ,fontWeight:"bold",fontSize:"12px"}}>{t('timeline.priceFrom')} 75 CHF</Box>

            <Typography style={{ marginBottom: '10%', marginTop: '10%',fontSize:"13px",fontWeight:"bold" }}>{t('timeline1.awareness')}</Typography>
            <button className="btn popup-protect-btn"            onClick={handleClickSensi}

                                >
                               {t('timeline1.book')}
                                </button>                </Box>
        </Box>

        <Box className="ct-box ct-box-bottom">
          <Box className="ct-date">
            <br/>
            <Typography style={{ fontSize: '30px', height: '5px' }}>📍</Typography>
          </Box>
          <Box className="ct-box-content">
            <Typography style={{ marginBottom: '6%' ,fontWeight:"bold",fontSize:"12px" }}>{t('timeline1.theoreticalExam')}</Typography>
            <Typography style={{ fontSize: '14px', color: '#7898b8' }}>
              <Link to="https://ge.ch/cari-online/examensPublic" className={classes.linkStyle}>{t('timeline1.registerForTheoreticalExam')}</Link>
            </Typography>
          </Box>
        </Box>

        <Box className="ct-box ct-box-top">
          <Box className="ct-date">
            <Typography style={{ fontSize: '30px', height: '95px',marginTop:"10px"  }}>📍</Typography>
          </Box>
          <Box className="ct-box-content">
          <Box  style={{ marginLeft: '1%', backgroundColor: '#B0E0E6', color: 'red' ,fontWeight:"bold",fontSize:"12px"}}>{t('timeline.priceFrom')} 75 CHF</Box>

            <Typography style={{ marginBottom: '10%', marginTop: '10%',fontSize:"13px",fontWeight:"bold" }}>{t('timeline1.theory')}</Typography>
            <button className="btn popup-protect-btn" onClick={handleClickTheory}
                                   
                                >
                               {t('timeline1.book')}
                                </button>                </Box>
        </Box>

        <Box className="ct-box ct-box-bottom">
          <Box className="ct-date">
            <br/>
            <Typography style={{ fontSize: '30px', height: '5px' }}>📍</Typography>
          </Box>
          <Box className="ct-box-content">
          <Typography style={{ marginBottom: '6%' ,fontSize:"13px",fontWeight:"bold"}}>{t('timeline1.registrationForm')}</Typography>
            <Typography style={{ fontSize: '14px', color: '#7898b8' }}>
            <Link to="https://www.ge.ch/document/12711/telecharger" className={classes.linkStyle}>{t('timeline1.downloadForm')}</Link>
            </Typography>

          </Box>
        </Box>

        <Box className="ct-box ct-box-top">
          <Box className="ct-date">
            <Typography style={{ fontSize: '30px', height: '95px',marginTop:"10px" }}>📍</Typography>
          </Box>
          <Box className="ct-box-content">
          <Box  style={{ marginLeft: '1%', backgroundColor: '#B0E0E6', color: 'red' ,fontWeight:"bold",fontSize:"12px"}}>{t('timeline.priceFrom')} 75 CHF</Box>

            <Typography style={{ marginBottom: '10%', marginTop: '5%',fontSize:"13px",fontWeight:"bold"}}>{t('timeline1.firstAid')}</Typography>
            <button className="btn popup-protect-btn"
                         onClick={handleClickFirstAid}
                                   
                                >
                               {t('timeline1.book')}
                                </button>          </Box>
        </Box>
      </Box>

      <ModalComponent isOpen={isOpen} onRequestClose={handleClose} />

    </div>
  );
};

export default CustomTimeline;
