import React from 'react';
import { TextField, FormControl, FormHelperText, Checkbox, FormControlLabel } from '@material-ui/core';
import useStyles from './styles'; // Import styles
import { useTranslation } from 'react-i18next';
import { FormData, Item } from './types'; // Import the types


interface InformationStepProps {
    formData: FormData;
    formErrors: { [key in keyof FormData]: string };
    handleInputChange: (prop: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InformationStep: React.FC<InformationStepProps> = ({ formData, formErrors, handleInputChange, handleCheckboxChange }) => {
    const classes = useStyles(); // Use styles
const {t} = useTranslation();
    return (
        <div>
        <TextField
            label={t('labels.nom')}
            type="text"
            fullWidth
            required
            value={formData.nom}
            onChange={handleInputChange('nom')}
            margin="normal"
            error={!!formErrors.nom}
            helperText={formErrors.nom && t(formErrors.nom)}
        />
        <TextField
            label={t('labels.prenom')}
            type="text"
            fullWidth
            required
            value={formData.prenom}
            onChange={handleInputChange('prenom')}
            margin="normal"
            error={!!formErrors.prenom}
            helperText={formErrors.prenom && t(formErrors.prenom)}
        />
        <TextField
            label={t('labels.dateNaissance')}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            required
            value={formData.dateNaissance}
            onChange={handleInputChange('dateNaissance')}
            margin="normal"
            error={!!formErrors.dateNaissance}
            helperText={formErrors.dateNaissance && t(formErrors.dateNaissance)}
        />
        <TextField
            label={t('labels.email')}
            type="email"
            fullWidth
            required
            value={formData.email}
            onChange={handleInputChange('email')}
            margin="normal"
            error={!!formErrors.email}
            helperText={formErrors.email && t(formErrors.email)}
        />
        <TextField
            label={t('labels.telephone')}
            type="tel"
            fullWidth
            required
            value={formData.telephone}
            onChange={handleInputChange('telephone')}
            margin="normal"
            error={!!formErrors.telephone}
            helperText={formErrors.telephone && t(formErrors.telephone)}
        />
{/*         <TextField
            label={t('labels.adresse')}
            type="text"
            fullWidth
            required
            value={formData.adresse}
            onChange={handleInputChange('adresse')}
            margin="normal"
            error={!!formErrors.adresse}
            helperText={formErrors.adresse && t(formErrors.adresse)}
        />
        <TextField
            label={t('labels.numeroregister')}
            type="text"
            fullWidth
            required
            value={formData.numeroregister}
            onChange={handleInputChange('numeroregister')}
            margin="normal"
            error={!!formErrors.numeroregister}
            helperText={formErrors.numeroregister && t(formErrors.numeroregister)}
        />*/} 
        <FormControlLabel
            control={
                <div>
                    <Checkbox
                        checked={formData.conditionsAcceptees}
                        onChange={handleCheckboxChange}
                        name="conditionsAcceptees"
                    />
                    <FormHelperText>{formErrors.conditionsAcceptees && t(formErrors.conditionsAcceptees)}</FormHelperText>
                </div>
            }
            label={t('labels.conditionsAcceptees')}
        />
    </div>
    );
};

export default InformationStep;
