import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Avatar, Grid, Divider, Button } from "@material-ui/core";
import { MdPeople, MdAccessTime } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import translateText from './translate';

const GenericCard = ({ courseData }) => {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();

  const [translatedTitle, setTranslatedTitle] = useState(courseData.title);
  const [translatedSubtitle, setTranslatedSubtitle] = useState(courseData.subtitle);
  const [groupe, setGroupe] = useState("en groupe")
  const [duree, setDuree] = useState("Durée")

  useEffect(() => {
    const fetchTranslations = async () => {
      const translatedTitleText = await translateText(courseData.title, i18n.language);
      const translatedSubtitleText = await translateText(courseData.subtitle, i18n.language);
      const groupeTr = await translateText("en groupe", i18n.language);
      const duree = await translateText("Durée", i18n.language);

      
      setTranslatedTitle(translatedTitleText);
      setTranslatedSubtitle(translatedSubtitleText);
      setGroupe(groupeTr)
      setDuree(duree)
    };

    fetchTranslations();
  }, [courseData.title, courseData.subtitle, i18n.language]);
  const handleClick = () => {
    
    // Corrected conditional logic using || for OR checks
    if (courseData.id === 15 || courseData.id === 68) {
      handleClickFirstAid(courseData.id);
    } else if (courseData.id === 16 || courseData.id === 67) {
      handleClickSensi(courseData.id);
    } else if (courseData.id === 17 || courseData.id === 69) {
      handleClickTheory(courseData.id);
    }
  };

  const handleClickTheory = (id) => {
    // Handle navigation logic here based on course type or other conditions
    navigate(`/${i18n.language}/Theory/${id}`);
  };
  const handleClickSensi = (id) => {
    // Handle navigation logic here based on course type or other conditions
    navigate(`/${i18n.language}/Sensibilisation/${id}`);
  };
  const handleClickFirstAid = (id) => {
    // Handle navigation logic here based on course type or other conditions
    navigate(`/${i18n.language}/FirstAid/${id}`);
  };

  return (
      <Card style={{ cursor: 'pointer' }}>
        <CardMedia
          style={{ height: 160 }}
          image={courseData.image}
          title='Project Image'
        />
        <CardContent style={{ paddingTop: 16 }}>
          <Box>
            <Typography variant='h6' style={{ marginBottom: 4, fontWeight: 'bold', color: 'black' }}>{translatedTitle}</Typography>
            <Typography variant='subtitle2' style={{ fontWeight: 400, color: 'black' }}>
              {translatedSubtitle}
            </Typography>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
            <div className="price-table__prices">
              <Typography variant="body1" style={{ color: '#FF0000', fontWeight: 'bold' }}>
                {courseData.promotionalPrice !== 0 && (
                  <>
                    <span style={{ color: '#00796b', fontFamily: 'Montserrat', fontSize: '20px' }}>{courseData.promotionalPrice} CHF</span>
                    <br />
                    <span style={{ textDecoration: 'line-through', marginRight: 8 }}>{courseData.price} CHF</span>
                  </>
                )}
                {courseData.promotionalPrice === 0 && (
                  <span style={{ color: '#00796b', fontWeight: 'bold' }}>{courseData.price} CHF</span>
                )}
              </Typography>
            </div>
          </Box>
          <Box style={{ marginTop: 16, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <Box style={{ marginRight: 8, display: 'flex', alignItems: 'center' }}>
              <Avatar variant='rounded' style={{ marginRight: 4 }}>
                <MdPeople style={{ color: '#00796b' }} />
              </Avatar>
              <Box>
                <Typography variant='caption' style={{ fontWeight: 'bold' }}>
                  Participants
                </Typography>
                <Typography variant='body2'>
                 {groupe}
                </Typography>
              </Box>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar variant='rounded' style={{ marginRight: 4 }}>
                <MdAccessTime style={{ color: '#00796b' }} />
              </Avatar>
              <Box>
                <Typography variant='caption' style={{ fontWeight: 'bold' }}>
                  {duree}
                </Typography>
                <Typography variant='body2'>
                  {courseData.duration}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider style={{ marginTop: 16 }} />
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
          <button className="btn popup-protect-btn" onClick={handleClick}>
          {t('priceTable1.inscription')}
            </button>
          </Box>
        </CardContent>
      </Card>
  );
};

export default GenericCard;
