import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import image1 from './images/unnamed.jpg'
interface CategoryProps {
    imgSrc: string;
    name: string;
    titre: string;
    desc: string;
    desc1: string;
    desc2: string;
    desc3: string;
    desc4: string;
    reservationUrl?: string; // optional for components that navigate to other pages
}

const CategoryLeft: React.FC<CategoryProps> = ({ imgSrc, name, titre, desc, desc1, desc2, desc3, desc4, reservationUrl }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

    const handleClick = () => {
        if (reservationUrl) {
            navigate(reservationUrl);
        }
    };

    return (
        <div className="category-wrapper" >
            <Box className="categoryes__item" style={{ display: 'flex', textAlign: 'center', paddingRight:'5%', paddingLeft:'5%' }}>
                <Box className="container">
                    <Box className="row">

                        <Box  style={{ display: 'flex', textAlign: 'center' , marginTop: "-10%"}}>
                            <Box>
                                <Typography variant="h4" className="categoryes__name" style={{ color: '#002d72', fontSize: "30px"}}>{name}</Typography>
                                <Box className="col-md-3">
                                    <figure className="categoryes__fig" style={{  paddingBottom: '5%' }}>
                                        <img src={imgSrc} style={{ width: '200px', height: '200px' }} alt={name} />
                                    </figure>
                                </Box>
                                <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", fontSize: "19px", fontWeight: "550"}}>{titre}</Typography>
                                <ul style={{ paddingInlineStart: "0", color: 'black', fontSize: '20px' }}>
                                    <li style={{ marginBottom: "8px", textAlign: 'left' }}>{desc}</li>
                                    <li style={{ marginBottom: "8px", textAlign: 'left' }}>{desc1}</li>
                                    <li style={{ marginBottom: "8px", textAlign: 'left' }}>{desc2}</li>
                                    <li style={{ marginBottom: "8px", textAlign: 'left' }}>{desc3}</li>
                                    <li style={{ textAlign: 'left' }}>{desc4}</li>
                                </ul>
                            </Box>
                        </Box>
                        <Box style={{ paddingTop: '50px' }}>
                            <button className="btn popup-protect-btn" onClick={handleClick} style={{ marginTop: '2%', marginBottom: '-2%' }}>
                                <span className="btn__text">Inscription</span>
                                <div className="data-form" data-title="Eliminating fear and excitement behind the wheel" data-duration="30 days" data-icon="" data-img="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/card3-262x262.jpg"></div>
                            </button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

const Categories = () => {
    const { t, i18n } = useTranslation();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    return (
        <div className="categoryes">
             <CategoryLeft
                imgSrc={image1}
                name={t('categoryLeft.basicCourse4H.name')}
    titre={t('categoryLeft.basicCourse4H.titre')}
    desc={t('categoryLeft.basicCourse4H.desc')}
    desc1={t('categoryLeft.basicCourse4H.desc1')}
    desc2={t('categoryLeft.basicCourse4H.desc2')}
    desc3={t('categoryLeft.basicCourse4H.desc3')}
    desc4={t('categoryLeft.basicCourse4H.desc4')}
    reservationUrl="/moto-info"
/>

<CategoryLeft
                imgSrc="https://my-ds.ch/wp-content/uploads/2022/11/my-driving-school-tarifs-cours-1-er-secours@2x.jpg"
                name={t('categoryLeft.basicCourse12H.name')}
    titre={t('categoryLeft.basicCourse12H.titre')}
    desc={t('categoryLeft.basicCourse12H.desc')}
    desc1={t('categoryLeft.basicCourse12H.desc1')}
    desc2={t('categoryLeft.basicCourse12H.desc2')}
    desc3={t('categoryLeft.basicCourse12H.desc3')}
    desc4={t('categoryLeft.basicCourse12H.desc4')}
    reservationUrl="/moto-info-1"
/>

<CategoryLeft
                imgSrc="https://my-ds.ch/wp-content/uploads/2022/11/my-driving-school-tarifs-cours-1-er-secours@2x.jpg"
                name={t('categoryLeft.privateLesson.name')}
    titre={t('categoryLeft.privateLesson.titre')}
    desc={t('categoryLeft.privateLesson.desc')}
    desc1={t('categoryLeft.privateLesson.desc1')}
    desc2={t('categoryLeft.privateLesson.desc2')}
    desc3={t('categoryLeft.privateLesson.desc3')}
    desc4={t('categoryLeft.privateLesson.desc4')}
/>

<CategoryLeft
                imgSrc="https://my-ds.ch/wp-content/uploads/2022/11/my-driving-school-tarifs-cours-1-er-secours@2x.jpg"
                name={t('categoryLeft.individualInitiationCourse.name')}
    titre={t('categoryLeft.individualInitiationCourse.titre')}
    desc={t('categoryLeft.individualInitiationCourse.desc')}
    desc1={t('categoryLeft.individualInitiationCourse.desc1')}
    desc2={t('categoryLeft.individualInitiationCourse.desc2')}
    desc3={t('categoryLeft.individualInitiationCourse.desc3')}
    desc4={t('categoryLeft.individualInitiationCourse.desc4')}
/>
        </div>
    );
};

export default Categories;
