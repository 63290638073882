import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, makeStyles, AppBar, Toolbar, Button, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import logo from './images/logo-nobackground-200.jpeg'; // with import
import { MdMenu } from "react-icons/md"; // Import the Menu icon
import translateText from './translate';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'white',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    height: 90,
  },
  logoContainer: {
    marginLeft: '20px',
    flexGrow: 1, // Grow to take up remaining space
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Center items vertically
  },
  menuButton: {
    marginRight: theme.spacing(2),
    fontSize: '29px',
  },
}));

interface Buttons {
  label: string;
  path: string;
}

const SidebarComponent: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [translatedButtons, setTranslatedButtons] = useState<Buttons[]>([]);
  const { i18n } = useTranslation();
  
  const buttons: Buttons[] = [
    { label: "Accueil", path: "/" },
    { label: "Cours", path: "/price/" },
    { label: "Auto", path: "/courses/" },
    { label: "Moto", path: "/coursesPageMOTO/" },
    { label: "À propos", path: "/AboutUs/" },
    { label: "Contact", path: "/Contact/" },
  ];

  useEffect(() => {
    const fetchTranslations = async () => {
      const translatedLabels = await Promise.all(
        buttons.map(async (button) => {
          const translatedLabel = await translateText(button.label, i18n.language); // Translate each button label
          return { ...button, label: translatedLabel }; // Update the label with translated text
        })
      );

      setTranslatedButtons(translatedLabels); // Update state with translated buttons
    };

    fetchTranslations();
  }, [i18n.language]);

  const handleNavigation = (path: string) => {
    // Add the current language to the URL
    const lngPath = `/${i18n.language}${path}`;
    navigate(lngPath);
    setMobileOpen(false); // Close drawer on navigation
  };

  const toggle = () => {
    setMobileOpen(!mobileOpen); // Toggle the state of mobileOpen
  };

  const drawerContent = (
    <List>
      {translatedButtons.map((button, index) => (
        <ListItem button key={index} onClick={() => handleNavigation(button.path)}>
          <ListItemText primary={button.label} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Button onClick={toggle} className={classes.menuButton}>
            <MdMenu />
          </Button>
          <Box display="flex" alignItems="center">
            <div className={classes.logoContainer}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </div>
          </Box>
          <LanguageSwitcher />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default SidebarComponent;
