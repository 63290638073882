import React from 'react';

import './footer.css';

import FooterMobile from './FooterMobile';
import FooterDesktop from './FooterDesktop';

class FooterWrapper extends React.Component{
    render(){
        return(
          
                <FooterDesktop />

        )
    }
}

export default FooterWrapper;