import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ToolbarComponent from './ToolbarComponent';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';

const ConditionsGenerales: React.FC = () => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    return (
        <>
            <ToolbarComponent />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: isMobile ? 0 : "40px", marginBottom: "10%", marginTop: isMobile ? "60px" : "3px" }}>
                <div className="container">
                    <div className="col-md-7" style={{ width: '100%' }}>
                        <div className="about__body">
                            <div className="about__body-inner">
                                {/* General Conditions Section */}
                                <h2 className="title" style={{ color: '#002d72', fontSize: '30px' }}>
                                    <span className="title__mark">{t('generalConditionsTitle')}</span>
                                </h2>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('generalConditionsDesc')}</p>

                                {/* First Aid Section */}
                                <h3 className="title__mark" style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('firstAidTitle')}</h3>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('firstAidPreamble')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('firstAidDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('firstAidCancellationTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('firstAidCancellationDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('firstAidPaymentTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('firstAidPaymentDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('firstAidPacksTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('firstAidPacksDesc')}</p>

                                {/* Theory Section */}
                                <h3 className="title__mark" style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('theoryTitle')}</h3>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('theoryPreamble')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('theoryDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('theoryCancellationTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('theoryCancellationDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('theoryPaymentTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('theoryPaymentDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('theoryPacksTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('theoryPacksDesc')}</p>

                                {/* Awareness Section */}
                                <h3 className="title__mark" style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('awarenessTitle')}</h3>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('awarenessPreamble')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('awarenessDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('awarenessCancellationTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('awarenessCancellationDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('awarenessPaymentTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('awarenessPaymentDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('awarenessPacksTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('awarenessPacksDesc')}</p>

                                {/* Motorcycle Section */}
                                <h3 className="title__mark" style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('motorcycleTitle')}</h3>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('motorcyclePreamble')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('motorcycleDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('motorcycleEquipmentTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('motorcycleEquipmentDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('motorcycleCancellationTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('motorcycleCancellationDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('motorcyclePaymentTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('motorcyclePaymentDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('motorcyclePacksTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('motorcyclePacksDesc')}</p>

                                {/* Car Section */}
                                <h3 className="title__mark" style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('carTitle')}</h3>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('carPreamble')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('carDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('carCancellationTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('carCancellationDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('carPaymentTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('carPaymentDesc')}</p>
                                <h5 className="title__mark" style={{ fontSize: '20px' }}>{t('carPacksTitle')}</h5>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('carPacksDesc')}</p>

                                {/* Data Protection Section */}
                                <h3 className="title__mark" style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('dataProtectionTitle')}</h3>
                                <p className="categoryes__desc1" style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>{t('dataProtectionDesc')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConditionsGenerales;
