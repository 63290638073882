// PriceTableConduite.jsx
import React, { useState, useEffect } from 'react';
import ModalComponent from './ModalComponent';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import translateText from './translate'; // Adjust the path as per your project structure
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

interface PriceTableItemProps {
  
  id: number;
  name: string;
  price: number;
  promotionalPrice: number;
  duree: string;
 
}

interface Item {
  id: number;
  name: string;
  price: number;
  promotionalPrice: number;
  type: string;
  duree: string;
  features: string[];
 
  sessions: { sessionDetails: string; remainingSpot: number }[];
}

const PriceTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [translatedItems, setTranslatedItems] = useState<Item[]>([]);
  const { t,i18n } = useTranslation(); // Access the translation function
  const [data, setData] = useState<Item[]>([]);

  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n])

 
  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await axios.get<Item[]>('https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons');
      
          let langue ='FRANCAIS';

          if(lng==='en'){
            langue="ENGLISH"
          }
         
        
            
          const filteredData = response.data.filter(item => item.type === 'Leçons motos' && item.name.includes(langue) );
       console.log()
          setData(filteredData);
          fetchTranslations();

      } catch (error) {
          console.error('Error fetching data:', error);
      }
  };

  fetchData();
    async function fetchTranslations() {
      try {
        const translations: Item[] = await Promise.all(
          data
        );

        setTranslatedItems(translations);
      } catch (error) {
        console.error('Error fetching translations:', error);
      }
    }

  }, [data,i18n.language]); // Listen to language change



  return (
    <>
    <br/>
    <br/>
    <br/>
    <div className="price" style={{ padding: '0', marginBottom: isMobile ? '40%' : '5%' }}>
      <div className="container">
        <h2 className="title price__title" style={{ textAlign: 'center', color: '#002d72', marginTop: isMobile ? '20%' : '4%' }}>
          <span className="title__mark">{t('ourcoursesTitle')}</span>
        </h2>

        <div className="price-table">
          <div className="price-table__list">
            {translatedItems.map((item, index) => (
              <PriceTableItem
                key={index}
                id={item.id}
                name={item.name}
                price={item.price}
                promotionalPrice={item.promotionalPrice}
                duree={item.duree}

              />
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const PriceTableItem = ({ id,name, price, duree,promotionalPrice  }: PriceTableItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
const navigate =useNavigate();
const{t,i18n}=useTranslation()
  const handleToggle = () => {
    if(name.includes("INDIV") || name.includes("EXAM")) {
      setIsOpen(!isOpen);

    }else{
      navigate(`/${i18n.language}/DetailCours/${id}`);
    }

  };

  const handleClick = () => {
    handleToggle();
    // Other actions you want to perform on "Inscription" click
  };

  return (
    <div className="col-md-4">
      <div className="price-table__item" style={{ backgroundColor: 'rgba(0,19,37,.9)', height: '260px' }}>
        <div className="price-table__value1" style={{ color: '#FFF', font: 'inherit', fontWeight: '500' }}>
          {name}
        </div>
        <br/>
        <br/>
        <ul className="price-table__feature-list" style={{ paddingLeft: '0' }}>
            <li  style={{ color: '#FFF', fontWeight: '500' }}>
              {duree}
            </li>
       
        </ul>

        {promotionalPrice && promotionalPrice !== 0 && (
                  <div className="price-table__name">
                    <span style={{ color: '#00796b', fontFamily: 'Montserrat', fontSize: '20px' }}>{promotionalPrice} CHF</span>
                    <br />
                    <span style={{ textDecoration: 'line-through', marginRight: 8 ,color:"red" }}>{price} CHF</span>
                  </div>
                )}
                {promotionalPrice === 0  && (
                  <div className="price-table__name" style={{ color: '#00796b', fontWeight: 'bold' }}>{price} CHF</div>
                )}
    
      
        <button className="btn price-table__btn popup-bestsellers-btn" onClick={handleClick} style={{ marginTop: '15px' }}>
          <span className="btn__text">{t('priceTable1.inscription')}</span>
        </button>
        <ModalComponent isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
      </div>
    </div>
  );
};

export default PriceTable;
