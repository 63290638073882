import React, { useState } from 'react';
import ModalComponent from './ModalComponent';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { Avatar, Box, Grow, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { faCarSide, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

import MotorcycleIcon from '@material-ui/icons/Motorcycle'; // Import Motorcycle icon from Material UI
import ToysIcon from '@material-ui/icons/Toys'; // Import Motorcycle icon from Material UI

import { CarFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BookingWizard from './BookingWizard';

const CourseList = () => {
  const { t,i18n } = useTranslation(); // Access the translation function

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Hook for programmatic navigation
  const handleClickTheory = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){
      navigate(`/${i18n.language}/Theory/17`);

    }else{
      navigate(`/${i18n.language}/Theory/69`);

      
    }
  };
  const handleClickSensi = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){

    navigate(`/${i18n.language}/Sensibilisation/16`);
    }else{
      navigate(`/${i18n.language}/Sensibilisation/67`);

    }
    
  };
  const handleClickFirstAid = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){

      navigate(`/${i18n.language}/FirstAid/15`);
    }else{
      navigate(`/${i18n.language}/FirstAid/68`);
  
      }
    
  };

  const handleClick = () => {
    navigate(`/${i18n.language}/CoursMoto` );

  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    handleToggle();
    // Autres actions à effectuer à la fermeture du modal
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  return (
    <div className="course course_index">
      <div className="container">
        <h2 className="title title_theme_white" style={{ marginTop: isMobile ? '3px' : '-50px' }}>
          <span className="title__mark" style={{ fontSize: '30px' }}>{t('courseTitle')}</span>
        </h2>
        <ul className="course__list row" style={{ marginRight: '2%' }}>
          <li className="course__list-item col-md-4">
            <div className="course__item course__item_consultation">
              <div className="course__item-name-container">
                <h3 className="course__item-name">{t('firstAidTitle1')}</h3>
              </div>

              <div className="course__item-popup">
                <div className="course__item-popup-name">{t('firstAidDesc1')}</div>
                <div className="course__item-popup-action">
                  <div className="course__item-popup-period">10 h</div>

                  <button className="btn popup-protect-btn" onClick={handleClickFirstAid}>
                    <span className="btn__text">{t('reserve')}</span>
                    {/*<div className="data-form" data-title="How to protect yourself and the car" data-duration="30 days" data-icon="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/shield.png" data-img="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/card1-262x262.jpg"> </div>*/}
                  </button>
                </div>
              </div>
            </div>
          </li>

          {/* Deuxième cours */}
          <li className="course__list-item col-md-4">
            <div className="course__item course__item_consultation1 ">
              <div className="course__item-name-container">
                <h3 className="course__item-name">{t('theoryTitle1')}</h3>
              </div>

              <div className="course__item-popup">
                <div className="course__item-popup-name">{t('theoryDesc1')}</div>
                <div className="course__item-popup-action">
                  <button className="btn popup-protect-btn" onClick={handleClickTheory}>
                    <span className="btn__text">{t('reserve')}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>

          {/* Troisième cours */}
          <li className="course__list-item col-md-4">
            <div className="course__item course__item_consultation2 ">
              <div className="course__item-name-container">
                <h3 className="course__item-name">{t('awarenessTitle1')}</h3>
              </div>

              <div className="course__item-popup">
                <div className="course__item-popup-name">{t('awarenessDesc1')}</div>
                <div className="course__item-popup-action">
                  <div className="course__item-popup-period">8 h</div>
                  <button className="btn popup-protect-btn" onClick={handleClickSensi}>
                    <span className="btn__text">{t('reserve')}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>

          {/* Quatrième cours */}
          <li className="course__list-item col-md-4">
            <div className="course__item course__item_consultation3">
              <div className="course__item-name-container">
                <h3 className="course__item-name">{t('drivingLessonTitle')}</h3>
              </div>
              <div className="course__item-popup">
                <div className="course__item-popup-name">{t('drivingLessonDesc')}</div>
                <div className="course__item-popup-action">
                  <div className="course__item-popup-period">45 ou 90 minutes</div>
                  <button className="btn popup-protect-btn" onClick={handleToggle}>
                    <span className="btn__text">{t('reserve')}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>

          {/*  Cinquième cours */}
          <li className="course__list-item col-md-4">
            <div className="course__item course__item_consultation4">
              <div className="course__item-name-container">
                <h3 className="course__item-name">{t('motorcycleCourseTitle')}</h3>
              </div>
              <div className="course__item-popup">
                <div className="course__item-popup-name">{t('motorcycleCourseDesc')}</div>
                <div className="course__item-popup-action">
                  <div className="course__item-popup-period">4 ou 12 h</div>
                  <button className="btn popup-protect-btn" onClick={handleClick}>
                    <span className="btn__text">{t('reserve')}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>

          {/*  septième cours */}
          <li className="course__list-item col-md-4">
            <div className="course__item course__item_consultation6">
              <div className="course__item-name-container">
                <h3 className="course__item-name">{t('licenseExchangeTitle')}</h3>
              </div>
              <div className="course__item-popup">
                <div className="course__item-popup-name">{t('licenseExchangeDesc')}</div>
                <div className="course__item-popup-action">
                  <button className="btn popup-protect-btn" onClick={handleToggle}>
                    <span className="btn__text">{t('learnMore')}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ModalComponent isOpen={isOpen} onRequestClose={handleClose} />

    </div>
  );
};

export default CourseList;
