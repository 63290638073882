import { Box, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

const ReservationSuccess = () => {
    const { t,i18n } = useTranslation();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
        <Box display="flex" justifyContent="center" alignItems="center" >
            <Card style={{ padding: 32, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <CardContent>
                  
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
                            <Typography variant="h6" style={{ marginTop: 16 }}>
                            {t('paymentSuccess.bookingSuccess')}
                            </Typography>
                        </Box>
                
                </CardContent>
            </Card>
        </Box>
        </>
    );
};

export default ReservationSuccess;
