import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Switch, styled } from '@material-ui/core';
import { FlagIcon } from 'react-flag-kit';
import { useNavigate, useLocation } from 'react-router-dom';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();  // Get the current location
  const currentLanguage = i18n.language === 'fr' ? 'FR' : 'GB';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lng = event.target.checked ? 'en' : 'fr';
    
    // Construct the new URL by replacing the current language in the path
    const newPath = location.pathname.replace(/^\/(fr|en)/, `/${lng}`);
    
    i18n.changeLanguage(lng);
    navigate(newPath);  // Navigate to the new URL with the updated language
  };

  return (
    <div>
      <FormGroup>
        <Box display="flex" alignItems="center">
          <FlagIcon code="FR" size={24} />
          <AntSwitch checked={currentLanguage === 'GB'} onChange={handleChange} />
          <FlagIcon code="GB" size={24} />
        </Box>
      </FormGroup>
    </div>
  );
};

export default LanguageSwitcher;
