import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHandshake, faClipboard, faBook, faUsers, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const AboutComponent = () => {
  const { t,i18n } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="container">
        <div className="col-md-7" style={{ width: '100%' }}>
          <div className="about__body1">
            <div className="about__body-inner">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left', width: '50%' }}>
                  <h2 className="title" style={{ color: '#002d72', fontWeight: 'bold', font: 'italic 1.7rem Montserrat', padding: '10px', fontSize: '27px' }}>
                    <span className="title__mark">{t('charterTitle')}</span>
                  </h2>
                  <div>
                    <h3 style={{ color: '#ff7300', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                      <FontAwesomeIcon icon={faFlag} style={{ marginRight: '15px', fontFamily: 'Montserrat' }} /> {t('notorietyTitle')}
                    </h3>
                    <p style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('notorietyBody') }}></p>
                  </div>
                  <div>
                    <h3 style={{ color: '#ff7300', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                      <FontAwesomeIcon icon={faHandshake} style={{ marginRight: '15px', fontFamily: 'Montserrat' }} /> {t('promiseTitle')}
                    </h3>
                    <p style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('promiseBody') }}></p>
                  </div>
                  <div>
                    <h3 style={{ color: '#ff7300', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                      <FontAwesomeIcon icon={faClipboard} style={{ marginRight: '15px', fontFamily: 'Montserrat' }} /> {t('followUpTitle')}
                    </h3>
                    <p style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('followUpBody') }}></p>
                  </div>
                </div>
                <div style={{ textAlign: 'left', width: '50%', paddingLeft: '4%' }}>
                  <div>
                    <h3 style={{ color: '#ff7300', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                      <FontAwesomeIcon icon={faBook} style={{ marginRight: '15px', fontFamily: 'Montserrat' }} /> {t('multidisciplinarityTitle')}
                    </h3>
                    <p style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('multidisciplinarityBody') }}></p>
                  </div>
                  <div>
                    <h3 style={{ color: '#ff7300', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                      <FontAwesomeIcon icon={faUsers} style={{ marginRight: '15px', fontFamily: 'Montserrat' }} /> {t('expertiseTitle')}
                    </h3>
                    <p style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('expertiseBody') }}></p>
                  </div>
                  <div>
                    <h3 style={{ color: '#ff7300', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                      <FontAwesomeIcon icon={faLightbulb} style={{ marginRight: '15px', fontFamily: 'Montserrat' }} /> {t('messageTitle')}
                    </h3>
                    <p style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('messageBody') }}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
