import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import secours from './images/secours.jpg';

interface FirstAidComponentProps {
  imgSrc: any;
  alt: string;
  name: string;
  desc: string | React.ReactNode;
  linkId: string;
  titre1: string;
  titre2?: string;
  desc2?: string;
  titre3?: string;
  desc3?: string;
  titre4?: string;
  desc4?: string;
  titre5?: string;
  desc5?: string;
  titre6?: string;
  desc6?: string;
  handleClick: any;
}
interface Item {
  id: number;
  type: string;
  name: string;
  price: number;
  promotionalPrice: number;
  features: string[];
}

const FirstAidComponent: React.FC<FirstAidComponentProps> = ({
  imgSrc,
  alt,
  name,
  desc,
  linkId,
  titre1,
  titre2,
  desc2,
  titre3,
  desc3,
  titre4,
  desc4,
  titre5,
  desc5,
  titre6,
  desc6,
  handleClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t,i18n } = useTranslation();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    console.log("Clic sur le bouton 'Nous contacter'");
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className="categoryes__item" style={{ textAlign: 'left' }}>
      <Box className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <figure className="categoryes__fig" style={{ paddingTop: isMobile ? '1px' : '15%', marginBottom: isMobile ? '5%' : '1px' }}>
              <img src={imgSrc} alt={alt} style={{ width: '100%', height: 'auto' }} />
            </figure>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box>
              <Typography variant="h4" className="categoryes__name" style={{ color: '#002d72', marginTop: "-5%" }}>
                {name}
              </Typography>
              <Typography variant="body1" className="categoryes__desc" style={{ color: 'black' }}>
                {desc}
              </Typography>
              <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", marginTop: "20px", fontSize: '23px' }}>
                {titre1}
              </Typography>
              <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                {desc2}
              </Typography>
              <Grid container spacing={3} style={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", fontSize: '23px' }}>
                    {titre3}
                  </Typography>
                  <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                    {desc3}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", fontSize: '23px' }}>
                    {titre4}
                  </Typography>
                  <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                    {desc4}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", fontSize: '23px' }}>
                    {titre5}
                  </Typography>
                  <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                    {desc5}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", fontSize: '23px' }}>
                    {titre6}
                  </Typography>
                  <Typography variant="body1" className="categoryes__desc" style={{ color: 'black', fontSize: '17px' }}>
                    {desc6}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', marginTop: isMobile ? '8%' : '2%' }}>
            <button className="btn popup-protect-btn" onClick={handleClick} style={{ fontSize: '16px', fontFamily: 'Helvetica' }}>
              <span className="btn__text">{t('reserve')}</span>
              <div
                className="data-form"
                data-title="Eliminating fear and excitement behind the wheel"
                data-duration="30 days"
                data-icon=""
                data-img="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/card3-262x262.jpg"
              ></div>
            </button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const FirstAid = () => {
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();
  const { packId } = useParams<{ packId: string }>();

  const [lessonDetails, setLessonDetails] = useState<Item | null>(null);
  const [payData, setPayData] = useState<number>(0);
  const [name, setName] = useState<string>("Theorie");

  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n])
  const handleButtonClick = () => {
    navigate(`/${i18n.language}/DetailCours/${packId}`);
  };

  useEffect(() => {
    axios.get<Item>(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons/${packId}`)
      .then(response => {
        setLessonDetails(response.data);
        setName(response.data.name);
        setPayData(response.data.promotionalPrice);
      })
      .catch(error => console.error('Error fetching lesson details:', error));
  }, [packId]);

  return (
    <>
      <br /><br /><br /><br /><br /><br /><br /><br />
      <div className="categoryes">
        <FirstAidComponent
          imgSrc={secours}
          alt={t('firstAid.alt')}
          name={t('firstAid.name')}
          desc={t('firstAid.description')}
          titre1={t('firstAid.title1')}
          desc2={t('firstAid.desc2')}
          titre3={t('firstAid.title3')}
          desc3={t('firstAid.desc3')}
          titre4={t('firstAid.title4')}
          desc4={t('firstAid.desc4')}
          titre5={t('firstAid.title5')}
          desc5={t('firstAid.desc5')}
          titre6={t('firstAid.title6')}
          desc6={`${payData} CHF`}
          linkId="b"
          handleClick={handleButtonClick}
        />
      </div>
    </>
  );
};

export default FirstAid;
