import React, { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ToolbarComponent from './ToolbarComponent';
import { useParams } from 'react-router-dom';

const LicenseExchangeInfo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t,i18n } = useTranslation();

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n])
    return (
        <>
            <ToolbarComponent />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: isMobile ? 0 : "40px", marginBottom: "10%", marginTop: isMobile ? "60px" : "3px" }}>
                <div className="container">
                    <div className="col-md-7" style={{ width: '100%' }}>
                        <div className="about__body">
                            <div className="about__body-inner">
                                <h2 className="title" style={{ color: '#002d72', fontSize: '30px' }}>{t('exchange.title')}</h2>
                                <p style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>
                                    {t('exchange.description')}
                                </p>

                                <h3 style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('exchange.useOfForeignLicenses')}</h3>
                                <p style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>
                                    {t('exchange.useOfForeignLicensesDescription')}
                                </p>

                                <h3 style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('exchange.exchangeWithoutTest')}</h3>
                                <p style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>
                                    {t('exchange.exchangeWithoutTestDescription')}
                                </p>

                                <h3 style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('exchange.exchangeWithTest')}</h3>
                                <p style={{ fontSize: '20px', color: 'black', fontWeight: '400' }}>
                                    {t('exchange.exchangeWithTestDescription')}
                                </p>

                                <h3 style={{ paddingTop: "40px", color: 'rgb(184, 39, 206)', fontSize: '26px' }}>{t('exchange.exchangeProcedure')}</h3>
                                <p style={{ fontSize: '20px', color: 'black', fontWeight: '400' }} dangerouslySetInnerHTML={{ __html: t('exchange.exchangeProcedureDescription') }}>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LicenseExchangeInfo;
