import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  propertyValue?: string; // New property
}

const ModalComponent: React.FC<ModalProps> = ({ isOpen, onRequestClose }) => {
  // Construct the URL based on the propertyValue
  const url = `https://successdriving.setmore.com/`;

  return (
    <Dialog open={isOpen} onClose={onRequestClose} maxWidth="xl" fullWidth>
      
      <iframe src={url} title="Success DS Booking Page" width="100%" height="800px" frameBorder="0"></iframe>
    </Dialog>
  );
};

export default ModalComponent;
