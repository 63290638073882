import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Button,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Card,
    Grid,
    Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SessionSelector from './SessionSelector';
import UserInfoForm from './UserInfoForm';
import Recap from './Recap';
import PaymentOptions from './PaymentOptions';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import translateText from './translate';
import axios from 'axios';
import { SessionDetail } from './types';
import StepData from './StepContent';
import moto from './images/moto_1.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)',
        minHeight: '100vh',
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    heroContainer: {
        position: 'relative',
        height: '450px',
        overflow: 'hidden',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        marginBottom: theme.spacing(6),
        background: 'linear-gradient(45deg, #6b48ff 0%, #8a63ff 100%)',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        },
    },
    heroImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        opacity: 0.85,
        transition: 'opacity 0.3s ease-in-out',
        '&:hover': {
            opacity: 1,
        },
    },
    heroTitle: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(1), // Réduit à 8px pour une bande mince
        color: '#fff',
        background: 'rgba(107,72,255,0.7)',
        borderRadius: '0 0 16px 16px',
        height: 'auto', // S'adapte au contenu
        minHeight: '-50px', // Bande très mince
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.1), // Réduit à 4px pour mobile
            minHeight: '40px', // Encore plus mince sur mobile
        },
    },
    titleText: {
        fontWeight: 400,
        fontSize: '0.1rem', // Réduit de 3rem à 2rem (32px)
        letterSpacing: '-0.5px',
        marginBottom: theme.spacing(1),
        fontFamily: "'Poppins', sans-serif",
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5rem', // Réduit de 2rem à 1.5rem (24px)
        },
    },
    priceText: {
        fontWeight: 400,
        fontSize: '0,5rem',
        fontFamily: "'Roboto', sans-serif",
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.6rem',
        },
    },
    programCard: {
        borderRadius: '20px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(5),
        marginBottom: theme.spacing(6),
        backgroundColor: '#fff',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 40px rgba(107, 72, 255, 0.15)',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    programTitle: {
        color: '#6b48ff',
        fontWeight: 700,
        marginBottom: theme.spacing(3),
        textAlign: 'center',
        fontSize: '2rem',
        fontFamily: "'Poppins', sans-serif",
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.6rem',
        },
    },
    featureItem: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
        fontSize: '1.2rem',
        color: '#4b5e6d',
        fontFamily: "'Roboto', sans-serif",
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    featureBullet: {
        color: '#8a63ff',
        marginRight: theme.spacing(1.5),
        fontWeight: 'bold',
        fontSize: '1.5rem',
    },
    stepperContainer: {
        padding: theme.spacing(4, 2),
        backgroundColor: '#fff',
        borderRadius: '20px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 1),
        },
    },
    formCard: {
        borderRadius: '20px',
        boxShadow: '0 6px 30px rgba(107, 72, 255, 0.1)',
        padding: theme.spacing(5),
        backgroundColor: '#fff',
        marginBottom: theme.spacing(6),
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0 10px 40px rgba(107, 72, 255, 0.2)',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        gap: theme.spacing(3),
    },
    button: {
        padding: theme.spacing(1.5, 5),
        fontWeight: 600,
        textTransform: 'none',
        fontSize: '1.1rem',
        borderRadius: '12px',
        fontFamily: "'Poppins', sans-serif",
        transition: 'all 0.3s ease',
    },
    backButton: {
        backgroundColor: '#a3b1bf',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8797a8',
            transform: 'translateY(-2px)',
        },
    },
    nextButton: {
        background: 'rgb(184, 39, 206)', // Couleur fixe basée sur votre référence
        color: '#fff',
        '&:hover': {
            background: 'rgb(206, 39, 184)', // Inversion légère pour l'effet hover
            transform: 'translateY(-2px)',
        },
    },
    promoText: {
        color: '#ffffff', // Blanc
        fontWeight: 700,
        fontSize: '1.4rem',
        marginTop: theme.spacing(1),
        fontFamily: "'Roboto', sans-serif",
        textShadow: `
        -1px -1px 0 #000000,
         1px -1px 0 #000000,
        -1px  1px 0 #000000,
         1px  1px 0 #000000,
        -1px  0px 0 #000000,
         1px  0px 0 #000000,
         0px -1px 0 #000000,
         0px  1px 0 #000000
    `, // Contour noir autour du texte
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem', // Ajustement mobile
        },
    },
}));

interface Item {
    id: number;
    type: string;
    name: string;
    price: number;
    promotionalPrice: number;
    features: string[];
    sessions: any[];
}

const formatSessionDetails = async (details, lng) => {
    const regex = /(\d+(?:ère|ème)? PARTIE)\s+(\w+)\s+(\d{2}\/\d{2}\/\d{4})\s+(\d{2}H\d{2}) à (\d{2}H\d{2})\s+(.+?)(?=\d+(?:ère|ème)? PARTIE|$)/g;
    const sessions: SessionDetail[] = [];

    let match;
    while ((match = regex.exec(details)) !== null) {
        const partTitle = match[1].trim();
        const dayOfWeek = match[2].trim();
        const date = match[3];
        const startTime = match[4];
        const endTime = match[5];
        const location = match[6].trim();

        let translatedTitle = '';
        let dateTitle = "Date";
        let dateText = `${dayOfWeek} `;
        let dateVal = `${date}`;
        let heureTitle = "Heure";
        let heureText = `${startTime} à ${endTime}`;
        let lieuTitle = "Lieu";

        try {
            translatedTitle = await translateText(partTitle, lng);
            dateTitle = await translateText("Date", lng);
            dateText = await translateText(dateText, lng);
            heureTitle = await translateText("Heure", lng);
            heureText = await translateText(heureText, lng);
            lieuTitle = await translateText("Lieu", lng);
        } catch (error) {
            console.error(`Error translating text:`, error);
            translatedTitle = partTitle;
        }

        sessions.push({
            title: translatedTitle,
            date: {
                title: dateTitle,
                value: dateText + dateVal
            },
            time: {
                title: heureTitle,
                value: heureText
            },
            location: {
                title: lieuTitle,
                value: location
            }
        });
    }

    return sessions;
};

const splitFeatures = (feature: string) => {
    return feature
        .replace(/^- */, '')
        .split(/ - |\n/)
        .map(f => f.trim());
};

const getMonthYear = (sessionDetails, language) => {
    const parts = sessionDetails.split(/\s+/);
    const dateString = parts.find(part => /\d{2}\/\d{2}\/\d{4}/.test(part));

    if (!dateString) {
        console.error('Date string not found in session details:', sessionDetails);
        return '';
    }

    const [day, month, year] = dateString.split('/');
    const date = new Date(year, month - 1, day);
    const formattedDate = new Intl.DateTimeFormat(language, { month: 'long', year: 'numeric' }).format(date);

    return formattedDate;
};

const DetailCoursView = () => {
    const classes = useStyles();
    const { packId } = useParams<{ packId: string }>();
    const { t, i18n } = useTranslation();
    const { lng } = useParams<{ lng: string }>();
    const navigate = useNavigate();
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const stepperContainerRef = useRef<HTMLDivElement>(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [activeStep, setActiveStep] = useState(0);
    const [translatedFeatures, setTranslatedFeatures] = useState<string[]>([]);
    const [formData, setFormData] = useState({
        session: '',
        nom: '',
        prenom: '',
        dateNaissance: '',
        email: '',
        telephone: '',
        numeroregister: '',
        conditionsAcceptees: false,
        paymentMethod: '',
    });
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [lessonDetails, setLessonDetails] = useState<Item | null>(null);
    const [payData, setPayData] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [formattedSession, setFormattedSession] = useState<SessionDetail[]>([]);
    const [formErrors, setFormErrors] = useState({
        session: '',
        nom: '',
        prenom: '',
        dateNaissance: '',
        email: '',
        telephone: '',
        numeroregister: '',
        adresse: '',
        conditionsAcceptees: '',
        paymentMethod: '',
    });
    const [isCodeValid, setIsCodeValid] = useState(true);
    const steps = [
        t('steps.0'),
        t('steps.1'),
        t('steps.2'),
        t('steps.3')
    ];

    useEffect(() => {
        if (lng && ['en', 'fr'].includes(lng)) {
            i18n.changeLanguage(lng);
        } else {
            console.error('Unsupported language:', lng);
        }
    }, [lng, i18n]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        axios.get(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons/${packId}`)
            .then(response => {
                setLessonDetails(response.data);
                setPayData(response.data);
                if (response.data.name.includes('AID') || response.data.name.includes('SECOURS')) {
                    setImageUrl('https://someurl.com/secours.jpg');
                } else if (response.data.name.includes('SENSIBILISATION')) {
                    setImageUrl('https://www.auto-ecole-europeenne.be/wp-content/uploads/2016/09/Sea-cours-theoriques-Auderghem.jpg');
                } else if (response.data.type.includes('motos') || response.data.name.includes('MOTO')) {
                    setImageUrl(moto);
                } else {
                    setImageUrl('https://sergio-auto-ecole.ch/site/wp-content/uploads/2022/02/Sensi.jpg');
                }
            })
            .catch(error => console.error('Error fetching lesson details:', error));
    }, [packId]);

    useEffect(() => {
        const fetchFormattedSession = async () => {
            if (formData.session) {
                try {
                    const sessions = await formatSessionDetails(formData.session, i18n.language);
                    const updatedSessions = sessions.map(session => ({
                        ...session,
                        location: {
                            ...session.location,
                            value: session.location.value.replace(/AGENCE CORNAVIN BEL AIR/g, 'AGENCE CORNAVIN BEL AIR<br/>')
                        }
                    }));
                    setFormattedSession(updatedSessions);
                } catch (error) {
                    console.error('Error formatting session details:', error);
                    setFormattedSession([]);
                }
            }
        };

        fetchFormattedSession();
    }, [formData.session, i18n.language]);

    useEffect(() => {
        if (lessonDetails && lessonDetails.features && lessonDetails.features.length > 0) {
            const translateFeatures = async () => {
                const translatedResults: string[] = [];

                for (const feature of lessonDetails.features) {
                    for (const splitFeature of splitFeatures(feature)) {
                        const translatedFeature = await translateText(splitFeature, i18n.language);
                        translatedResults.push(translatedFeature);
                    }
                }

                setTranslatedFeatures(translatedResults);
            };

            translateFeatures();
        }
    }, [lessonDetails, i18n.language]);

    useEffect(() => {
        if (formData.session && buttonContainerRef.current && activeStep === 0) {
            setTimeout(() => {
                buttonContainerRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }, 100);
        }
    }, [formData.session, activeStep]);

    useEffect(() => {
        if (activeStep === 1 && stepperContainerRef.current) {
            setTimeout(() => {
                stepperContainerRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                window.scrollBy(0, -20);
            }, 100);
        }
    }, [activeStep]);

    const handleCodeValidation = (code) => {
        return code === 'VALID_CODE';
    };

    const handleNext = () => {
        if (validateForm()) {
            if (activeStep === steps.length - 1) {
                setPaymentSuccessful(true);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFormData({
            session: '',
            nom: '',
            prenom: '',
            dateNaissance: '',
            email: '',
            telephone: '',
            numeroregister: '',
            conditionsAcceptees: false,
            paymentMethod: '',
        });
    };

    const handleInputChange = (field) => (event) => {
        setFormData({ ...formData, [field]: event.target.value });
    };

    const handleCheckboxChange = (event) => {
        setFormData({ ...formData, conditionsAcceptees: event.target.checked });
    };

    const isConfirmerDisabled = formData.paymentMethod === 'code' && !isCodeValid;

    const validateForm = () => {
        let valid = true;
        const newFormErrors = { ...formErrors };

        switch (activeStep) {
            case 0:
                if (!formData.session) {
                    newFormErrors.session = t('errors.session');
                    valid = false;
                }
                break;
            case 1:
                if (!formData.nom) {
                    newFormErrors.nom = t('errors.nom');
                    valid = false;
                }
                if (!formData.prenom) {
                    newFormErrors.prenom = t('errors.prenom');
                    valid = false;
                }
                if (!formData.dateNaissance) {
                    newFormErrors.dateNaissance = t('errors.dateNaissance');
                    valid = false;
                }
                if (!formData.email) {
                    newFormErrors.email = t('errors.email');
                    valid = false;
                } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
                    newFormErrors.email = t('errors.emailInvalid');
                    valid = false;
                }
                if (!formData.telephone) {
                    newFormErrors.telephone = t('errors.telephone');
                    valid = false;
                } else if (!/^[0-9]+$/.test(formData.telephone)) {
                    newFormErrors.telephone = t('errors.telephoneInvalid');
                    valid = false;
                }

                if (lessonDetails && (lessonDetails.name.includes('SENSIBILISATION') || lessonDetails.type.includes('Leçons motos'))) {
                    if (!formData.numeroregister) {
                        newFormErrors.numeroregister = t('errors.numeroregister');
                        valid = false;
                    }
                }

                if (!formData.conditionsAcceptees) {
                    newFormErrors.conditionsAcceptees = t('errors.conditionsAcceptees');
                    valid = false;
                }
                break;
            case 2:
                break;
            default:
                break;
        }

        setFormErrors(newFormErrors);
        return valid;
    };

    const getPromoText = () => {
        if (lessonDetails?.name.includes('SENSIBILISATION')) {
            const promoText = t('awareness.promoText', {
                price: lessonDetails.promotionalPrice,
                defaultValue: i18n.language === 'fr'
                    ? 'SENSIBILISATION (FRANÇAIS) -45% offre Limitée\nTarif: {{price}} CHF'
                    : 'AWARENESS (FRENCH) -45% Limited offer\nPrice: {{price}} CHF'
            });

            return (
                <Typography variant="h3" className={classes.promoText}>
                    {promoText}
                </Typography>
            );
        }
        return null;
    };

    return (
        <div className={classes.root}>
            {lessonDetails === null ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                    <CircularProgress style={{ color: '#6b48ff' }} size={60} thickness={5} />
                </Box>
            ) : (
                <>
                    <div className={classes.heroContainer}>
                        <img className={classes.heroImage} src={imageUrl} alt="Course Practical" />
                        <div className={classes.heroTitle}>
                            <Typography variant="h1" className={classes.titleText}>
                                {/* Contenu du titre */}
                            </Typography>
                            <Typography variant="h2" className={classes.priceText}>
                                {/* Contenu du prix */}
                            </Typography>
                            {getPromoText()}
                        </div>
                    </div>

                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Card className={classes.programCard}>
                                <Typography variant="h3" className={classes.programTitle}>
                                    {t('programme.title')}
                                </Typography>
                                <div>
                                    {translatedFeatures.map((feature, featureIndex) => (
                                        <Typography key={featureIndex} className={classes.featureItem}>
                                            <span className={classes.featureBullet}>•</span>
                                            {feature}
                                        </Typography>
                                    ))}
                                </div>
                            </Card>
                        </Grid>
                    </Grid>

                    <div ref={stepperContainerRef} className={classes.stepperContainer}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>

                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <Card className={classes.formCard}>
                                <StepData
                                    step={activeStep}
                                    lessonDetails={lessonDetails}
                                    formData={formData}
                                    setFormData={setFormData}
                                    formErrors={formErrors}
                                    handleInputChange={handleInputChange}
                                    handleCheckboxChange={handleCheckboxChange}
                                    payData={payData}
                                    setPaymentSuccessful={setPaymentSuccessful}
                                    classes={classes}
                                    formattedSession={formattedSession}
                                    t={t}
                                    isCodeValid={isCodeValid}
                                    handleCodeValidation={handleCodeValidation}
                                />

                                {!paymentSuccessful && (
                                    <div ref={buttonContainerRef} className={classes.buttonContainer}>
                                        <Button
                                            variant="contained"
                                            className={`${classes.button} ${classes.backButton}`}
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            {t('buttons.back')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={`${classes.button} ${classes.nextButton}`}
                                            onClick={handleNext}
                                            disabled={isConfirmerDisabled}
                                        >
                                            {activeStep === steps.length - 1 ? t('buttons.confirm') : t('buttons.next')}
                                        </Button>
                                    </div>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default DetailCoursView;