import React, { useEffect, useState } from 'react';
import { Container, Grid, Button, makeStyles, ButtonGroup, Typography } from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import TwoWheelerIcon from '@material-ui/icons/TwoWheeler';
import CommuteIcon from '@material-ui/icons/Commute';
import PriceTableITheorie from './PriceTableTheorie';
import PriceTableConduite from './PriceTableConduite';
import PriceTablePack from './PriceTablePack';
import PriceTableMoto from './PriceTableMoto';
import PackVoiture from './PackVoiture';
import PackMotos from './PackMotos';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles({
  container: {
    marginTop: '20px', // Adjust margin as needed
  },
  buttonGroup: {
    marginBottom: '20px', // Adjust margin as needed
  },
  button: {
    textTransform: 'none', // Prevents uppercase text in buttons
    borderRadius: '20px', // Adjust border radius as needed
    padding: '15px 30px', // Increase padding for larger buttons
    fontSize: '1.2rem', // Increase font size for larger buttons
  },
  selectedButton: {
    backgroundColor: '#2196f3', // Example of selected button color
    color: '#ffffff', // Example of text color for selected button
  },
  icon: {
    marginRight: '10px', // Adjust margin as needed
    fontSize: '2rem', // Increase icon size for larger buttons
  },
  tabTitle: {
    color: '#666', // Example of customizing text color
  },
});

const CourseTabSelect = ({type}) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState('auto'); // State to track selected tab
  const { t, i18n } = useTranslation(); // Initialize useTranslation hook

  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]);
  const tabData = [
    {
      id: 'auto',
      icon: <DirectionsCarIcon className={classes.icon} />,
      title: 'PERMIS AUTO',
    },
    {
      id: 'moto',
      icon: <TwoWheelerIcon className={classes.icon} />,
      title: 'PERMIS MOTO',
    }
  ];



  return (
    <div className="price price_index" style={{paddingTop: '50px', paddingBottom: '100px'}}>

    <Container className={classes.container}>
 

      {/* Additional content based on selected tab */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
      
          {/* Example: Display content based on selected tab */}
          {type === 'auto' && <> 
          <PackVoiture/> 
            <PriceTableConduite/>
            <PriceTableITheorie/>
           </>}
          {type === 'moto' && <>
          <PackMotos/>
          <PriceTableMoto/>
         
           </>}
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

export default CourseTabSelect;
