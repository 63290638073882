import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    Box,
    Stepper,
    Step,
    StepLabel,
    TextField,
    FormControlLabel,
    Checkbox,
    Grid,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    CircularProgress,
    Badge,
} from '@material-ui/core';
import { Card } from 'primereact/card';
import ReactHtmlParser from 'react-html-parser';

import { useTranslation } from 'react-i18next';
import ColorlibStepIcon from './ColorlibStepIcon';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { CiCalendar, CiWallet } from 'react-icons/ci';
import mastercardImage from './images/icons8-mastercard-48.png';
import cashImage from './images/icons8-en-espèces-48.png';
import PaymentSuccess from './PaymentSuccess';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import secours from './images/secours.jpg'; // with import
import translateText from './translate';
import { classNames } from 'primereact/utils';
import permis from './images/permis.jpeg'
import SessionDisplay from './SessionDisplay';
import { FormDataCours, SessionDetail, SessionSelectorProps } from './types'; // Import the types
import { useStyless } from './styles';

const formatSessionDetails = async (details, lng) => {
    // Regular expression to match each part of the session details
    const regex = /(\d+(?:ère|ème)? PARTIE)\s+(\w+)\s+(\d{2}\/\d{2}\/\d{4})\s+(\d{2}H\d{2}) à (\d{2}H\d{2})\s+(.+?)(?=\d+(?:ère|ème)? PARTIE|$)/g;
    console.log(details);
    const sessions: SessionDetail[] = [];

    // Iterate over matches found in the details string
    let match;
    while ((match = regex.exec(details)) !== null) {
        console.log(match)
        const partTitle = match[1].trim();
        const dayOfWeek = match[2].trim();
        const date = match[3];
        const startTime = match[4];
        const endTime = match[5];
        const location = match[6].trim();

        let translatedTitle = '';
        let dateTitle = "Date";
        let dateText = `${dayOfWeek} `;
        let dateVal = `${date}`;
        let heureTitle = "Heure";
        let heureText = `${startTime} à ${endTime}`;
        let lieuTitle = "Lieu";

        try {
            translatedTitle = await translateText(partTitle, lng);
            dateTitle = await translateText("Date", lng);
            dateText = await translateText(dateText, lng);
            heureTitle = await translateText("Heure", lng);
            heureText = await translateText(heureText, lng);
            lieuTitle = await translateText("Lieu", lng);
        } catch (error) {
            console.error(`Error translating text:`, error);
            translatedTitle = partTitle; // Use original title if translation fails
        }

        // Create an object for each session and push to the sessions array
        sessions.push({
            title: translatedTitle,
            date: {
                title: dateTitle,
                value: dateText + dateVal
            },
            time: {
                title: heureTitle,
                value: heureText
            },
            location: {
                title: lieuTitle,
                value: location
            }
        });
    }

    return sessions;
};


const getMonthYear = (sessionDetails, language) => {
    // Split the session details into parts
    const parts = sessionDetails.split(/\s+/);

    // Find the date part, which should be in the format "DD/MM/YYYY"
    const dateString = parts.find(part => /\d{2}\/\d{2}\/\d{4}/.test(part));

    if (!dateString) {
        console.error('Date string not found in session details:', sessionDetails);
        return '';
    }

    // Split the dateString into day, month, and year
    const [day, month, year] = dateString.split('/');

    // Create a Date object (months are 0-indexed in JavaScript Date object)
    const date = new Date(year, month - 1, day);

    // Format the date to "Month Year" using Intl.DateTimeFormat with the provided language
    const formattedDate = new Intl.DateTimeFormat(language, { month: 'long', year: 'numeric' }).format(date);

    return formattedDate;
};


const SessionSelector: React.FC<SessionSelectorProps> = ({ lessonDetails, formData, setFormData, formErrors }) => {
    const [selectedSession, setSelectedSession] = useState(formData.session);
    const { t, i18n } = useTranslation();
    const classes = useStyless()
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const [translatedDetails, setTranslatedDetails] = useState<Map<string, SessionDetail[]>>(new Map());

    useEffect(() => {
        const translateAllSessions = async () => {
            try {
                const sessionMap = new Map<string, SessionDetail[]>();
                const formattedDetailsPromises = lessonDetails.sessions.map(async (session) => {
                    const formattedDetails = await formatSessionDetails(session.sessionDetails, i18n.language);
                    sessionMap.set(session.sessionDetails, formattedDetails);
                });

                // Wait for all promises to resolve
                await Promise.all(formattedDetailsPromises);

                // Convert the Map to an array of objects
                const translatedSessions = Array.from(sessionMap.entries()).map(([sessionDetails, details]) => ({
                    sessionDetails,
                    details
                }));

                // Set the translated sessions to the state
                setTranslatedDetails(new Map(translatedSessions.map(({ sessionDetails, details }) => [sessionDetails, details])));

                // Log the translated details
                console.log(translatedSessions);
            } catch (error) {
                console.error('Error formatting and translating session details:', error);
            }
        };

        translateAllSessions(); // Invoke translation and formatting on mount or when i18n.language changes
    }, [lessonDetails.sessions, i18n.language]); // Depend on lessonDetails.sessions and i18n.language

    useEffect(() => {
        console.log('Translated details state:', translatedDetails);
    }, [translatedDetails]);
    const handleCardClick = (sessionDetails, session) => {
        if (session.remainingSpot > 0) {
            setSelectedSession(sessionDetails);
            setFormData({ ...formData, session: sessionDetails });
        }
    };

    const getXsValue = (totalItems) => {
        if (isMobile) return 12;
        if (totalItems === 1) return 12;
        if (totalItems === 2) return 6;
        return 4; // for 3 or more items
    };
    const xsValue = getXsValue(lessonDetails.sessions.length);
    const getFormattedDetails = (sessionDetails: string) => {
        return translatedDetails.get(sessionDetails) || [];
    };
    return (
        <Grid container spacing={2} className={classes.containerSession}>
            {lessonDetails?.sessions.map((session, index) => {
                const monthYear = getMonthYear(session.sessionDetails, i18n.language);
                const isSpotAvailable = session.remainingSpot > 0;
                const isCardDisabled = !isSpotAvailable;
                const noSpotAvailable = session.remainingSpot === 0;
                const isSelected = selectedSession === session.sessionDetails;
                const formattedDetails = getFormattedDetails(session.sessionDetails);
                console.log(formattedDetails);

                return (
                    <Grid item xs={xsValue} sm={12} md={6} lg={4} key={index} >
                        <Badge
                            color="error"
                            badgeContent="Complet"
                            invisible={!noSpotAvailable}
                        >
                            <Card
                                className={`session-card ${isSelected ? 'selected' : ''} ${isCardDisabled ? 'disabled' : ''}`}
                              
                              
                                style={{ backgroundColor: isSelected ? '#b827ce' : 'inherit', color: isSelected ? 'white' : 'inherit' ,              
                                borderRadius: '12px',
                                padding: '20px',
                                position: 'relative',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)', // Add shadow for depth
                                cursor: isSpotAvailable ? 'pointer' : 'not-allowed',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease'}}
                                onClick={() => handleCardClick(session.sessionDetails, session)}
                            >
                                <Typography variant="h6" component="h2" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                                    {monthYear}
                                </Typography>
                                <Typography variant="body2" component="pre">
                                    {formattedDetails.length > 0 ? (
                                        <SessionDisplay
                                            formattedSessions={formattedDetails} // Display the details
                                        />
                                    ) : (
                                        'Loading...'
                                    )}
                                </Typography>
                            </Card>
                        </Badge>
                        {formErrors.session && selectedSession === session.sessionDetails && (
                            <FormHelperText error>{formErrors.session}</FormHelperText>
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};
export default SessionSelector;  