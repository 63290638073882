import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ModalComponent from './ModalComponent';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import translateText from './translate';
import { Divider, Typography } from '@douyinfe/semi-ui';

import ReactHtmlParser from 'react-html-parser';

interface Item {
  id: number;
  type: string;
  name: string;
  price: number;
  promotionalPrice: number;
  features: string[];
}

const PackVoiture = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { lng } = useParams<{ lng: string }>();
  useEffect(() => {
    if (lng && ['en', 'fr'].includes(lng)) {
      i18n.changeLanguage(lng);
    } else {
      // Handle unsupported language or redirect
      console.error('Unsupported language:', lng);
    }
  }, [lng, i18n]); 
  const [data, setData] = useState<Item[]>([]);
  const [langue, setLangue] = useState('FRANCAIS');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (i18n.language === 'en') {
          setLangue("ENGLISH")
        } else {
          setLangue("FRANCAIS")
        }
        const response = await axios.get<Item[]>('https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons');

        const filteredData = response.data.filter(item => item.type === 'Pack' && !item.name.includes('MOTO') && item.name.includes(langue));

        // Group data by criteria
        const plusData = filteredData.filter(item => item.name.includes('+'));
        console.log(plusData)

        const completData = filteredData.filter(item => item.name.includes('COMPLET') || item.name.includes('BUSINESS'));
        console.log(completData)

        const otherData = filteredData.filter(item => !item.name.includes('+') && !item.name.includes('COMPLET') && !item.name.includes('BUSINESS'));
        console.log(otherData)

        // Sort each group
        plusData.sort((a, b) => a.price - b.price);
        completData.sort((a, b) => a.price - b.price);
        otherData.sort((a, b) => a.price - b.price);

        // Combine the sorted groups
        const combinedData = [...plusData, ...completData, ...otherData];

        setData(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Runs only once on component mount
  }, [i18n.language, langue]);

  const getRibbonClass = (index: number) => {
    const classes = ['ribbon6 orange', 'ribbon6 green', 'ribbon6 yellow', 'ribbon6 green', 'ribbon6 yellow'];
    return classes[index % classes.length];
  };

  const handleIconClick = () => {
    navigate("/DetailPageMobile");
    window.scrollTo(0, 0);
  };

  const splitFeatures = (feature: string) => {
    return feature
      .trim()
      .replace(/^- */, '')
      .split(/ - |\n/)
      .map(f => f.trim());
  };

  const renderPriceTableItems = (items: Item[], keyPrefix: string) => {
    return items.map((item, index) => (
      <PriceTableItem
        key={`${keyPrefix}-${index}`}
        index={item.id}
        value={item.name}
        features={splitFeatures(item.features[0])}
        dataPriceIn={`${item.promotionalPrice} CHF`}
        dataPrice={`${item.price} CHF`}
        dataName={item.name}
        dataActive="no" // Adjust as per your data structure
        showIcon={index === 1} // Example condition for showing icon
      />
    ));
  };

  const DividerWithText = ({ text }) => (
    <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
      <Divider style={{ flex: 1 }} />
      <Typography style={{ margin: '0 10px' }}>{text}</Typography>
      <Divider style={{ flex: 1 }} />
    </div>
  );


  return (
    <div className="price" style={{ padding: '0' }}>
      <div className="container">
        <h2 className="title price__title" style={{ textAlign: 'center', color: '#002d72', marginTop: isMobile ? '20%' : '9%' }}>
          <span className="title__mark" style={{ fontSize: '35px' }}>{t('priceTable.title')}</span>
        </h2>
        <div className="price-table">
          <div className="price-table__list">

            <Divider style={{ margin: '20px 0' }} >
              {t('packPlus')}
            </Divider>
            <br />
            {renderPriceTableItems(data.filter(item => item.name.includes('+')), 'plus')}

            <Divider style={{ margin: '20px 0' }} >
              {t('packComplet')}
            </Divider>
            <br />
            {renderPriceTableItems(data.filter(item => (item.name.includes('COMPLET') || item.name.includes('BUSINESS')) ), 'complet')}

            <Divider style={{ margin: '20px 0' }} >
            {t('packConduiteOnly')} 
            </Divider>
            <br />
            {renderPriceTableItems(data.filter(item => !item.name.includes('+') && !item.name.includes('COMPLET') &&  !item.name.includes('BUSINESS')), 'other')}

          </div>
        </div>
      </div>
    </div>
  );
};

export default PackVoiture;

interface PriceTableItemProps {
  index: number;
  value: string;
  features: string[];
  dataPriceIn: string;
  dataPrice: string;
  dataName: string;
  dataActive: string; // Adjust type as per your data structure
  showIcon: boolean;
}

const PriceTableItem: React.FC<PriceTableItemProps> = ({
  index,
  value,
  features,
  dataPriceIn,
  dataPrice,
  dataName,
  dataActive,
  showIcon,
}) => {
  const { t,i18n } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  const [translatedValue, setTranslatedValue] = useState(value); // State for translated value
  const [translatedFeatures, setTranslatedFeatures] = useState<string[]>(features); // State for translated features

  useEffect(() => {
    const fetchTranslations = async () => {
      const translatedValueText = await translateText(value, i18n.language);
      const translatedFeaturesTexts = await Promise.all(features.map(feature => translateText(feature, i18n.language)));

      setTranslatedValue(translatedValueText);
      setTranslatedFeatures(translatedFeaturesTexts);
    };

    fetchTranslations();
  }, [value, features, i18n.language]);

  const handleClick = () => {
    navigate(`/${i18n.language}/DetailPageMobile/` + index);
    // Additional actions you want to perform on "Inscription" button click
  };

  // Function to split value into two lines based on parentheses
  const renderValue = (value: string) => {
    if (value.length > 25) {
      const indexSpace = value.indexOf(' ', 10); // Find first space after 25 characters
      if (indexSpace !== -1) {
        return (
          <>
            {value.substring(0, indexSpace).trim()} <br /> {/* First line */}
            {value.substring(indexSpace).trim()} {/* Second line */}
          </>
        );
      } else {
        return value; // If no space found, return original value
      }
    } else {
      const indexParentheses = value.indexOf('(');
      if (indexParentheses !== -1) {
        return (
          <>
            {value.substring(0, indexParentheses).trim()} <br /> {/* First line */}
            {value.substring(indexParentheses).trim()} {/* Second line */}
          </>
        );
      }
    }
  };

  return (
    <div className="col-md-4">
      <div className="price-table__item" style={{ height: '365px' }}>
        <div className="wrap">
          <span className={getRibbonClass(index)}><span>{dataPriceIn}</span></span>
        </div>
        <div className="price-table__value" style={{ color: '#002d72' }}>{renderValue(translatedValue)}</div>
        <h3 className="price-table__name" style={{ color: '#000' }}> <del>{dataPrice}</del> </h3>
        <ul className="price-table__feature-list">
          {translatedFeatures.map((feature, featureIndex) => (
            <li key={featureIndex} className="price-table__feature-item">{ReactHtmlParser(feature)}</li>
          ))}
        </ul>
        <button className="btn price-table__btn popup-bestsellers-btn" onClick={handleClick}>
          <span className="btn__text">{t('priceTable1.inscription')}</span>
          <div className="data-form" data-price={dataPrice} data-name={dataName} data-active={dataActive}></div>
        </button>
      </div>
    </div>
  );
};

const getRibbonClass = (index: number) => {
  const classes = ['ribbon6 orange', 'ribbon6 green', 'ribbon6 yellow', 'ribbon6 green', 'ribbon6 yellow'];
  return classes[index % classes.length];
};
