import React from 'react';
import { Typography } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import SessionPres from './SessionPres';

const Recap = ({ formattedSession, formData, lessonDetails, t, classes }) => (
    <div className={classes.recapContainer}>
        <Typography variant="h6" className={classes.recapItem}>{t('recap.title')}</Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>Session:</span> <SessionPres formattedSessions={formattedSession} />
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.nom')}:</span> {formData.nom}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.prenom')}:</span> {formData.prenom}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.dateNaissance')}:</span> {formData.dateNaissance}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.email')}:</span> {formData.email}
        </Typography>
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.telephone')}:</span> {formData.telephone}
        </Typography>
        {lessonDetails && (lessonDetails.name.includes('SENSIBILISATION') || lessonDetails.type.includes('Leçons motos')) &&
            <>
                <Typography className={classes.recapItem}>
                    <span className={classes.boldText}>{t('recap.numeroregister')}:</span> {formData.numeroregister}
                </Typography>
            </>
        }
        <Typography className={classes.recapItem}>
            <span className={classes.boldText}>{t('recap.conditionsAcceptees')}:</span> {formData.conditionsAcceptees ? t('common.yes') : t('common.no')}
        </Typography>
    </div>
);

export default Recap;
