import React from 'react';
import UserInfoForm from './UserInfoForm';
import Recap from './Recap';
import PaymentOptions from './PaymentOptions';
import SessionSelector from './SessionSelector';

const StepData = ({
    step,
    lessonDetails,
    formData,
    setFormData,
    formErrors,
    handleInputChange,
    handleCheckboxChange,
    payData,
    setPaymentSuccessful,
    classes,
    formattedSession,
    t,
    isCodeValid,
    handleCodeValidation // New prop
}) => {
    switch (step) {
        case 0:
            return (
                <SessionSelector
                    lessonDetails={lessonDetails}
                    formData={formData}
                    setFormData={setFormData}
                    formErrors={formErrors}
                />
            );
        case 1:
            return (
                <UserInfoForm
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleCheckboxChange={handleCheckboxChange}
                    formErrors={formErrors}
                    lessonDetails={lessonDetails}
                    t={t}
                />
            );
        case 2:
            return (
                <Recap
                    formattedSession={formattedSession}
                    formData={formData}
                    lessonDetails={lessonDetails}
                    t={t}
                    classes={classes}
                />
            );
        case 3:
            return (
                <PaymentOptions
                    formData={formData}
                    handleInputChange={handleInputChange}
                    payData={payData}
                    setPaymentSuccessful={setPaymentSuccessful}
                    handleCodeValidation={handleCodeValidation} // Pass the function
                />
            );
        default:
            return <div>Unknown step</div>;
    }
};

export default StepData;
