import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Timeline } from '@douyinfe/semi-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcaseMedical, faNoteSticky, faSchool, faCar, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalComponent from './ModalComponent';

const stepsData = {
  en: [
    { title: "First_Aid", price: "75", promotionalPrice: "60" },
    { title: "Registration_Form" },
    { title: "Theory", price: "75", promotionalPrice: "60" },
    { title: "Theoretical_Exam", price: "75" },
    { title: "Awareness", price: "75" },
    { title: "Driving_Lessons", price: "75" },
    { title: "Practical_Exam", price: "75" },
  ],
  fr: [
    { title: "First_Aid", price: "75", promotionalPrice: "60" },
    { title: "Registration_Form" },
    { title: "Theory", price: "75", promotionalPrice: "60" },
    { title: "Theoretical_Exam", price: "75" },
    { title: "Awareness", price: "75" },
    { title: "Driving_Lessons", price: "75" },
    { title: "Practical_Exam", price: "75" },
  ]
};

const MyTimeline: React.FC = () => {
  const { t, i18n } = useTranslation(); 
  const [steps, setSteps] = useState(stepsData.en);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
const navigate = useNavigate();
const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSteps(stepsData[i18n.language as keyof typeof stepsData]);
  }, [i18n.language]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    handleToggle();
    // Autres actions à effectuer à la fermeture du modal
  };
  const renderIcon = (index: number) => {
    switch (index) {
      case 0:
        return <FontAwesomeIcon icon={faSuitcaseMedical} style={{ width: '30px', height: '30px' }} />;
      case 1:
        return <FontAwesomeIcon icon={faNoteSticky} style={{ width: '30px', height: '30px' }} />;
      case 2:
        return <FontAwesomeIcon icon={faSchool} style={{ width: '30px', height: '30px' }} />;
      case 3:
        return <FontAwesomeIcon icon={faGraduationCap} style={{ width: '30px', height: '30px' }} />;
      case 4:
        return <FontAwesomeIcon icon={faSchool} style={{ width: '30px', height: '30px' }} />;
      case 5:
        return <FontAwesomeIcon icon={faCar} style={{ width: '30px', height: '30px' }} />;
      case 6:
        return <FontAwesomeIcon icon={faGraduationCap} style={{ width: '30px', height: '30px' }} />;
      default:
        return null;
    }
  };

  const handleClickTheory = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){
      navigate(`/${i18n.language}/Theory/17`);

    }else{
      navigate(`/${i18n.language}/Theory/69`);

      
    }
  };
  const handleClickSensi = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){

    navigate(`/${i18n.language}/Sensibilisation/16`);
    }else{
      navigate(`/${i18n.language}/Sensibilisation/67`);

    }
    
  };
  const handleClickFirstAid = () => {
    // Handle navigation logic here based on course type or other conditions
    if(i18n.language==='fr'){

      navigate(`/${i18n.language}/FirstAid/15`);
    }else{
      navigate(`/${i18n.language}/FirstAid/68`);
  
      }
    
  };
  const handleClick = (index) => {
    if(index===0){
      handleClickFirstAid()
    }
    if(index===2){
      handleClickTheory()
    }

    if(index===4){
      handleClickSensi()
    }
    if(index===5){
      handleToggle()
    }
  }
  return (
    <Box className="timeline-container">
      <Typography variant="h4" className="timeline-title" style={{ color: "rgb(0, 45, 114)", marginBottom: "20px", fontSize: "28px", fontWeight: "600", marginRight: '10%', marginLeft: '10%' }}>
        {t('timeline5.steps')}
      </Typography>
      <Typography variant="body1" className="timeline-description" style={{ fontSize: "18px", marginBottom: "30px", marginRight: '10%', marginLeft: '10%' }}>
        {t('timeline5.stepsDescription')}
      </Typography>
      <Divider />
      <Timeline mode="alternate">
        {steps.map((step, index) => (
          <Timeline.Item key={index} dot={renderIcon(index)}>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              {t(`timeline5.${step.title}`)}
            </Typography>
            {index !== 1 && index !==3  && index !==6 && (
              <Typography variant="body1" className="step-price">
            {t('timeline.priceFrom')}    {step.price} CHF
              </Typography>
            )}
                  {index === 1 ? (
              <Link to="https://www.ge.ch/document/12711/telecharger">{t('timeline1.downloadForm')}</Link>
            ) : index === 3 ? (
              <Link to="https://ge.ch/cari-online/examensPublic">{t('timeline1.registerForTheoreticalExam')}</Link>
            ) : index === 6 ? (
              <Link to="https://ge.ch/cari-online/examensPublic">{t('timeline1.registerForPracticalExam')}</Link>
            ) : (
              <Button variant="contained" fullWidth style={{ backgroundColor: "#B827CE", color: "white", fontWeight: "600" }} onClick={() => handleClick(index)}>
                {t('timeline1.book')}
              </Button>
            )}
          </Timeline.Item>
        ))}
      </Timeline>
      <ModalComponent isOpen={isOpen} onRequestClose={handleClose} />

    </Box>
  );
};

export default MyTimeline;
