import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const handleButtonClick = () => {
  // Logique supplémentaire si nécessaire avant la navigation
  console.log("Clic sur le bouton 'Nous contacter'");
};
const AboutComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
  const { t,i18n } = useTranslation();

  return (
    <Box className="about" display="flex" alignItems="center" justifyContent="center">
      <Box className="container" style={{ marginLeft: isMobile ? '0.5%' : '22%', width: '100%' }}>
        <Box className="col-md-7">
          <Box className="about__body">
            <Box className="about__body-inner">
              <Typography variant="h4" className="title" style={{ color: '#002d72', marginBottom: '20px', marginTop: isMobile ? '20%' : '1%' }}>
                <span className="title__mark" style={{ color: '#B827CE' }}>{t('aboutTitle')}</span>
              </Typography>
              <Typography variant="body1" style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('aboutIntro') }} />
              <br />
              <Typography variant="body1" style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('aboutExperience') }} />
              <br />
              <Typography variant="body1" style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('aboutMeetingPoints') }} />
              <br />
              <Typography variant="body1" style={{ color: '#002d72', fontSize: '18px' }} dangerouslySetInnerHTML={{ __html: t('aboutSupport') }} />
              <br />
              <Box style={{ color: '#FFFFFF' }}>
                <Link to="/Contact" className="btn about__btn" onClick={handleButtonClick}>
                  <span className="btn__text" style={{ fontSize: '14px' }}>{t('contactUs')}</span>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutComponent;
