import React, { useState } from 'react';
import {
    Grid,
    Button,
    TextField,
} from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import mastercardImage from './images/icons8-mastercard-48.png';
import stripePromise from './stripeP';

// Load Stripe with your public key

const PaymentOptions = ({ formData, handleInputChange, payData, setPaymentSuccessful, handleCodeValidation }) => {
    // State to manage code input and validation status
    const [code, setCode] = useState('');
    const [isCodeValid, setIsCodeValid] = useState(true);

    // Handler for code input change
    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    // Handler for code payment validation
    const handleCodePayment = () => {
        // Here you would validate the code and set payment successful if the code is valid
        if (code === 'VALID_CODE') { // Replace with your code validation logic
            setIsCodeValid(true);
            handleCodeValidation(true); // Update the parent component state
        } else {
            setIsCodeValid(false);
            handleCodeValidation(false); // Update the parent component state
        }
    };

    return (
        <div className="payment-options">
            <Grid container spacing={2}>
                {/* Option to pay by card */}
                <Grid item xs={6}>
                    <div className={`rent-radio ${formData.paymentMethod === 'card' ? 'active' : ''}`}>
                        <label className="custom_radio">
                            <div className="radio-content">
                                <img src={mastercardImage} alt="Mastercard" className="radio-image" />
                                <span className="rent-option">Payer par carte</span>
                            </div>
                            <input
                                type="radio"
                                className="rent-types"
                                name="paymentMethod"
                                value="card"
                                checked={formData.paymentMethod === 'card'}
                                onChange={handleInputChange('paymentMethod')}
                                required
                            />
                            <span className="checkmark-circle" />
                        </label>
                    </div>
                </Grid>

                {/* Commenting out the code related to payment by code */}
                {/*
                <Grid item xs={6}>
                    <div className={`rent-radio ${formData.paymentMethod === 'code' ? 'active' : ''}`}>
                        <label className="custom_radio">
                            <div className="radio-content">
                                <span className="rent-option">Payer avec un code</span>
                            </div>
                            <input
                                type="radio"
                                className="rent-types"
                                name="paymentMethod"
                                value="code"
                                checked={formData.paymentMethod === 'code'}
                                onChange={handleInputChange('paymentMethod')}
                                required
                            />
                            <span className="checkmark-circle" />
                        </label>
                    </div>
                </Grid>
                */}

            </Grid>

            {formData.paymentMethod === 'card' && (
                <Elements stripe={stripePromise}>
                    <PaymentForm formData={formData} payData={payData} setPaymentSuccessful={setPaymentSuccessful} />
                </Elements>
            )}

            {/*
            {formData.paymentMethod === 'code' && (
                <div className="code-input">
                    <TextField
                        label="Code"
                        variant="outlined"
                        fullWidth
                        value={code}
                        onChange={handleCodeChange}
                        error={!isCodeValid}
                        helperText={!isCodeValid ? "Code invalide" : ""}
                    />
                    <br/>
                    <br/>
                    <br/>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCodePayment}
                        className="validate-button"
                    >
                        Valider le code
                    </Button>
                </div>
            )}
            */}
        </div>
    );
};

export default PaymentOptions;
