import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import moto from './images/moto_1.jpg'; // with import
import { useTranslation } from 'react-i18next';
import BookingWizard from './BookingWizard';
import { } from 'react-router-dom';
import image1 from './images/unnamed.jpg'
import image2 from './images/unnamed-3.jpg'
import axios from 'axios';
import translateText from './translate';

interface CategoryProps {
    imgSrc: string;
    name: string;
    titre: string;
    desc: string;
    desc1: string;
    desc2: string;
    desc3: string;
    desc4: string;
    reservationUrl?: string; // optional for components that navigate to other pages
}

const CategoryLeft: React.FC<CategoryProps> = ({ imgSrc, name, titre, desc, desc1, desc2, desc3, desc4, reservationUrl }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
    const [isOpen, setIsOpen] = useState(false);
    const { t,i18n } = useTranslation(); // Access the translation function

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };


    const [translatedName, setTranslatedName] = useState<string>()

    useEffect(() => {
        const fetchTranslations = async () => {
            try {
                const transle = await translateText(name, i18n.language);
            

                setTranslatedName(transle);
            } catch (error) {
                console.error('Error fetching translations:', error);
            }
        };

        fetchTranslations();
    }, [i18n.language]);
    const handleClose = () => {
        handleToggle();
        // Autres actions à effectuer à la fermeture du modal
    };
    const handleClick = () => {
        let url = reservationUrl;
        if(name.includes("12")){
          url=  url+"-1";
        }
        if (url) {
            navigate(url);
        } else {
            handleToggle();
        }
    };

    return (
        <Box className="categoryes__item" style={{ textAlign: 'left' }}>
            <Box className="container">
                <Box className="row">
                    <Box className="col-md-3">
                        <figure className="categoryes__fig" style={{ paddingTop: isMobile ? '0' : '10%', paddingBottom: '10%' }}>
                            <img src={imgSrc} style={{ width: '250px', height: '250px' }} alt={name} />
                        </figure>
                    </Box>
                    <Box className="col-md-9">
                        <Box>
                            <Typography variant="h4" className="categoryes__name" style={{ color: '#002d72', fontSize: "30px", marginTop: "-5%" }}>{translatedName}</Typography>
                            <Typography variant="h5" style={{ color: "rgb(0, 45, 114)", fontSize: "19px", fontWeight: "550", marginTop: "10px" }}>{titre}</Typography>
                            <ul style={{ listStyleType: "none", paddingInlineStart: "0", color: 'black', fontSize: '20px' }}>
                                <li style={{ marginBottom: "8px" }}>{desc}</li>
                                <li style={{ marginBottom: "8px" }}>{desc1}</li>
                                <li style={{ marginBottom: "8px" }}>{desc2}</li>
                                <li style={{ marginBottom: "8px" }}>{desc3}</li>
                                <li style={{ marginBottom: "8px" }}>{desc4}</li>
                            </ul>
                        </Box>
                    </Box>
                    <Box style={{ paddingTop: '50px', paddingLeft: "25%" }}>
                        <button className="btn popup-protect-btn" onClick={handleClick} style={{ marginLeft: '2%', marginTop: '2%', marginBottom: '-2%' }}>
                            <span className="btn__text">Inscription</span>
                            <div className="data-form" data-title="Eliminating fear and excitement behind the wheel" data-duration="30 days" data-icon="" data-img="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/card3-262x262.jpg"></div>
                        </button>
                    </Box>
                </Box>
            </Box>


        </Box>

    );
};

interface Item {
    id: number;
    name: string;
    price: number;
    promotionalPrice: number;
    type: string;
    features: string[];
    duree: string;
    sessions: { sessionDetails: string; remainingSpot: number }[];
}

const Categories = () => {
    const { t,i18n } = useTranslation(); // Access the translation function
    const [data, setData] = useState<Item[]>([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<Item[]>('https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons');
                const filteredData = response.data.filter(item => item.type === 'Leçons motos' && (item.name.includes('COURS DE BASES') || item.name.includes('MOTOBIKE BASIC')));
             console.log(filteredData)
                setData(filteredData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className="categoryes">
            {data.map((course, index) => (
                <CategoryLeft
                    imgSrc={image1}
                    name={course.name}
                    titre={t('categoryLeft.basicCourse4H.titre')}
                    desc={t('categoryLeft.basicCourse4H.desc')}
                    desc1={t('categoryLeft.basicCourse4H.desc1')}
                    desc2={t('categoryLeft.basicCourse4H.desc2')}
                    desc3={t('categoryLeft.basicCourse4H.desc3')}
                    desc4={t('categoryLeft.basicCourse4H.desc4')}
                    reservationUrl="/moto-info"
                />
                ))}

           
       {/*     <CategoryLeft
                imgSrc={image2}
                name={t('categoryLeft.privateLesson.name')}
                titre={t('categoryLeft.privateLesson.titre')}
                desc={t('categoryLeft.privateLesson.desc')}
                desc1={t('categoryLeft.privateLesson.desc1')}
                desc2={t('categoryLeft.privateLesson.desc2')}
                desc3={t('categoryLeft.privateLesson.desc3')}
                desc4={t('categoryLeft.privateLesson.desc4')}
            />

            <CategoryLeft
                imgSrc={moto}
                name={t('categoryLeft.individualInitiationCourse.name')}
                titre={t('categoryLeft.individualInitiationCourse.titre')}
                desc={t('categoryLeft.individualInitiationCourse.desc')}
                desc1={t('categoryLeft.individualInitiationCourse.desc1')}
                desc2={t('categoryLeft.individualInitiationCourse.desc2')}
                desc3={t('categoryLeft.individualInitiationCourse.desc3')}
                desc4={t('categoryLeft.individualInitiationCourse.desc4')}
            /> */}

        </div>
    );
};

export default Categories;
