import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const { t,i18n } = useTranslation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');
        const bookingId = queryParams.get('booking_id');
        if (sessionId) {


            axios.post(`https://success-ds-geneva-66508d24e048.herokuapp.com/api/verify-payment?session_id=${sessionId}`)
                .then(response => {
                    if (response.data.message === 'Booking saved successfully') {
                        setSuccess(true);
                    } else {
                        setError('Failed to verify payment');
                    }
                })
                .catch(error => {
                    console.error('Error verifying payment:', error);
                    setError('Failed to verify payment');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError('Session ID not found');
            setLoading(false);
        }
    }, [location.search]);

    return (
        <>
        <br>
        </br>
        <br/>
        <br/>
        <br/>
        <br/>  <br/>
        <br/>  <br/>
        <br/>  <br/>
        <br/>  <br/>
        <br/>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Card style={{ padding: 32, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                    <CardContent>
                        {loading && <CircularProgress size={60} />}
                        {error && <Typography variant="h6" color="error">{error}</Typography>}
                        {success && (
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
                                <Typography variant="h6" style={{ marginTop: 16 }}>
                                    {t('paymentSuccess.bookingSuccess')}
                                </Typography>
                                <Typography variant="caption" style={{ marginTop: 16 }}>
                                    {t('paymentSuccess.bookingSuccess')}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default PaymentSuccess;
