import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import GenericCard from './CourseBar'; // Adjust the import path accordingly
import { useTranslation } from 'react-i18next';
import secours from './images/secours.jpg'; // with import

// Define the interface for the data items
interface Course {
  id: number;
  name: string;
  price: number;
  promotionalPrice: number;
  type: string;
  features: string[];
  duree: string;
  participants:number;
  sessions: { sessionDetails: string; remainingSpot: number }[];
}

const PriceTableTheorie = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const navigate = useNavigate();
const {t ,i18n } = useTranslation()

const { lng } = useParams<{ lng: string }>();
useEffect(() => {
  if (lng && ['en', 'fr'].includes(lng)) {
    i18n.changeLanguage(lng);
  } else {
    // Handle unsupported language or redirect
    console.error('Unsupported language:', lng);
  }
}, [lng, i18n])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<Course[]>('https://success-ds-geneva-66508d24e048.herokuapp.com/api/lessons');
      let langue ='FRANCAIS';

      if(i18n.language==='en'){
        langue="ENGLISH"
      }
     
    
      const orderMap: { [key: string]: number } = {
        'SECOURS': 1,
        'FIRST AID': 1,
        'THEORIE': 2,
        'THEORY': 2,
        'SENSIBILISATION': 3,
      };

      // Filter and sort courses
      const filteredCourses = response.data.filter(course => course.type.includes('Cours') && course.name.includes(langue));

      filteredCourses.sort((a, b) => {
        const aOrder = Object.keys(orderMap).find(key => a.name.includes(key)) || 'ZZZ';
        const bOrder = Object.keys(orderMap).find(key => b.name.includes(key)) || 'ZZZ';

        return (orderMap[aOrder] || 999) - (orderMap[bOrder] || 999);
      });

        setCourses(filteredCourses);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [i18n.language]);

  const handleCardClick = (courseId: number) => {
    // Handle navigation based on course ID or other identifier
    navigate(`/${i18n.language}/course-details/${courseId}`);
  };

  return (
    <div className="price" style={{ padding: '0' }}>
      <div className="container">
        <h2 className="title price__title" style={{ textAlign: 'center', color: '#002d72', paddingTop: '5%', fontSize: '37px' }}>
          <span className="title__mark">{t('ourcoursesTitle')}</span>
        </h2>
        <div className="price-table">
          <Grid container spacing={3}>
            {courses.map((course, index) => {
              let imageUrl = 'https://www.auto-ecole-europeenne.be/wp-content/uploads/2016/09/Sea-cours-theoriques-Auderghem.jpg'; // Default image URL

              if (course.name.includes('AID') || course.name.includes('SECOURS')) {
                imageUrl = secours;
              } else if (course.name.includes('SENSIBILISATION')) {
                imageUrl = 'https://www.auto-ecole-europeenne.be/wp-content/uploads/2016/09/Sea-cours-theoriques-Auderghem.jpg';
              }else{
                imageUrl='https://sergio-auto-ecole.ch/site/wp-content/uploads/2022/02/Sensi.jpg'
              }
              if (course.name.includes('SECOURS') || course.name.includes('FIRST AID')) {
                course.participants = 12;
              } else if (course.name.includes('SENSIBILISATION')) {
                course.participants = 12;
              } else {
                course.participants = 18;
              }

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <GenericCard
                    courseData={{
                      id: course.id,
                      title: course.name,
                      subtitle: 'en salle', // Example subtitle
                      price: course.price,
                      promotionalPrice: course.promotionalPrice,
                      participants: course.participants,
                      duration: course.duree,
                      image: imageUrl // Conditionally set image URL
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default PriceTableTheorie;
