import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHandshake, faClipboard, faBook, faUsers, faLightbulb, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'; // Import necessary icons
import ModalComponent from './ModalComponent'; // Make sure you have a modal component
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next'; // Import translation hook

const MotoInfo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t,i18n } = useTranslation(); // Access the translation function

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n]); 
    const handleButtonClick = () => {
        // Additional logic if necessary before navigation
        console.log("Click on 'Contact Us' button");
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile


    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="container">
                <div className="col-md-7" style={{ width: '95%', marginLeft: '2%', marginTop: "10%" }}>
                    <div className="about__body1">
                        <div className="about__body-inner">
                            <div style={{ textAlign: 'center' }}>
                                <h2 className="title" style={{ color: '#002d72', fontWeight: 'bold', font: '1.7rem Montserrat', padding: '10px', fontSize: '27px', marginTop:  isMobile ? "20%" : "1%" }}>
                                    <span className="title__mark" >{t('fourHourPracticalCourse')}</span>
                                </h2>
                                <div>
                                    <h3 style={{ display: 'flex', alignItems: 'center', fontSize: '20px', marginTop: "4%", marginBottom: "2%" }}>
                                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: 'red', marginRight: '15px', fontFamily: 'Montserrat', fontSize: '2em' }} />
                                        <span className="title__mark">{t('mandatoryPracticalCourseForPracticalExam')}</span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        {t('date')}:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            {t('from')} 28 {t('april')} {t('to')} 30 {t('april')}
                                        </span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        {t('schedule')}:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            🔸 {t('firstDay')} (4H): 17h30
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: "87px", color: '#000', fontSize: '20px' }}>
                                            🔸 {t('secondDay')} (4H): 9h00
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        <span style={{ marginLeft: "87px", color: '#000', fontSize: '20px' }}>
                                            🔸 {t('thirdDay')} (4H): 9h00
                                        </span>
                                    </h3>
                                </div>
                                
                                
                                <div>
                                <h3 style={{  textAlign: 'left', fontSize: '18px', marginTop: "4%", marginBottom: "2%" }}>
                                        <span className="title__mark" style={{ textAlign: 'left'}}> {t('weOfferThreeDifferent4HourCourses')}</span>
                                    </h3>
                                
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        {t('price')}:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            110 CHF ({t('inGroup')})
                                        </span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px', marginBottom: "1%" }}>
                                        {t('language')}:
                                        <span style={{ marginLeft: "10px", color: '#000', fontSize: '20px' }}>
                                            {t('french')}
                                        </span>
                                    </h3>
                                </div>
                                <div>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        {t('conditions')}:
                                        <span style={{ marginLeft: isMobile ? '3%' :"1%", color: '#000', fontSize: '20px' }}>
                                            🔺 {t('requiredEquipment')} ({t('mandatory')})
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: isMobile ? '35%' : "12%", color: '#000', fontSize: '20px' }}>
                                            🔺 {t('studentPermitAorA1')} ({t('mandatory')})
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: isMobile ? '37%' : "12%", color: '#000', fontSize: '20px' }}>
                                            🔺 {t('vehiclePermit')} ({t('mandatory')})
                                        </span>
                                    </h3>
                                    <h3 style={{ color: '#002d72', display: 'flex', alignItems: 'center', fontSize: '23px' }}>
                                        <span style={{ marginLeft: isMobile ? '39%' : "12%", color: '#000', fontSize: '20px' }}>
                                            🔺 {t('vehicleNotProvided')}
                                        </span>
                                    </h3>
                                </div>
                                <button className="btn popup-protect-btn" onClick={handleButtonClick} style={{ marginBottom:"20px", marginTop:'3%' }}>
                                    <span className="btn__text">{t('reserve')}</span>
                                    <div className="data-form" data-title="Eliminating fear and excitement behind the wheel" data-duration="30 days" data-icon="" data-img="https://drivingschool.wpmix.net/wp-content/uploads/2017/04/card3-262x262.jpg"></div>
                                </button>
                                <ModalComponent isOpen={isOpen} onRequestClose={handleClose} />
                            </div>
                            <Link to="/CoursesPageMoto">
                                    <button style={{ marginTop: '10px' }}>
                                        {t('back')}
                                    </button>
                                </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MotoInfo;
