import React, { useEffect } from 'react';
import AboutComponent1 from './AboutComponent1';
import AboutComponent from './AboutComponent';
import ToolbarComponent from './ToolbarComponent';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BackgroundSlideshow = () => {
    const { t, i18n } = useTranslation(); // Initialize useTranslation hook

    const { lng } = useParams<{ lng: string }>();
    useEffect(() => {
      if (lng && ['en', 'fr'].includes(lng)) {
        i18n.changeLanguage(lng);
      } else {
        // Handle unsupported language or redirect
        console.error('Unsupported language:', lng);
      }
    }, [lng, i18n]);
    return (
        <div >
            <ToolbarComponent />
          { /* <h1 className='price-section'>L'école</h1>*/}
            <AboutComponent1/>
            <AboutComponent/>

        </div>
    );
};

export default BackgroundSlideshow; 
